<template>
  <div id="subscribe">
    <v-dialog v-model="loadingScreen" width="auto" persistent>
      <v-card>
        <v-card-actions
          class="d-flex justify-content-center align-items-center align-content-center"
        >
          <v-progress-circular color="primary" indeterminate>
          </v-progress-circular>
          <v-card-text class="subheading text-center">
            <b>{{ lang[getLanguage].WAIT_TITLE }}</b>
          </v-card-text>
          <v-card-text class="text-center">
            {{ lang[getLanguage].WAIT_DESCRIPTION }}
          </v-card-text>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="alertDialog" persistent max-width="500">
      <v-card class="alert-dialog">
        <v-icon v-if="this.alertStatus == 'success'" class="succes-icon"
          >mdi-check-circle</v-icon
        >
        <v-icon v-if="this.alertStatus == 'failed'" class="failed-icon"
          >mdi-close-circle</v-icon
        >
        <v-card-text class="title text-center">{{
          getLanguage === "en" ? this.alertText.en : this.alertText.id
        }}</v-card-text>
        <!-- <v-card-text class="text-center">
            {{ lang[getLanguage].BOOKING_FAILED_DESCRIPTION }}
          </v-card-text> -->
        <v-btn
          color="primary"
          :width="windowWidth < 768 ? '100%' : '50%'"
          @click="closeAlertDialog"
          class="color:#2962FF;margin-left:-10px;margin-top:-4px;font-weight:normal;font-size:11px;text-transform: none !important;"
          >Ok</v-btn
        >
      </v-card>
    </v-dialog>

    <v-slide-y-transition mode="out-in">
      <v-layout column pl-2 pr-2>
        <!-- ####################### SKELETON LOADERS ####################### -->

        <v-layout column v-if="loading">
          <p class="mt-0"></p>
          <v-layout column>
            <v-spacer></v-spacer>
            <v-skeleton-loader
              class="mx-auto"
              type="card"
              width="100%"
            ></v-skeleton-loader>
            <v-spacer></v-spacer>
          </v-layout>
        </v-layout>

        <!-- ############################################## COMPONENTS ##############################################  -->

        <v-layout column align-center v-else pt-2>
          <v-stepper
            v-model="stepper"
            flat
            alt-labels
            elevation="0"
            class="subscribe md:px-32"
            @change="scrollToTop"
          >
            <v-stepper-header>
              <v-stepper-step
                :complete="stepper > 1"
                complete-icon=""
                :class="['px-0 w-1/6 custom-stp-subs']"
                step="1"
              >
                <div class="text-center stepper-title">
                  <div :style="stepper === 1 ? 'color: #6b4693' : ''">
                    <div>1</div>
                    <div>
                      {{ lang[getLanguage].PRODUCT_STEP_1 }}
                    </div>
                  </div>
                </div>
              </v-stepper-step>
              <v-divider class="custom-divider-dashed"></v-divider>

              <v-stepper-step
                :complete="stepper > 2"
                complete-icon=""
                class="px-0 w-1/6 custom-stp-subs"
                step="2"
              >
                <div class="text-center stepper-title">
                  <div :style="stepper === 2 ? 'color: #6b4693' : ''">
                    <div>2</div>
                    <div>
                      {{ lang[getLanguage].PRODUCT_STEP_2 }}
                    </div>
                  </div>
                </div>
              </v-stepper-step>
              <v-divider class="custom-divider-dashed"></v-divider>

              <v-stepper-step
                :complete="stepper > 3"
                complete-icon=""
                class="px-0 w-1/6 custom-stp-subs"
                step="3"
              >
                <div class="text-center stepper-title">
                  <div :style="stepper === 3 ? 'color: #6b4693' : ''">
                    <div>3</div>
                    <div>
                      {{ lang[getLanguage].PRODUCT_STEP_3 }}
                    </div>
                  </div>
                </div>
              </v-stepper-step>
              <v-divider class="custom-divider-dashed"></v-divider>

              <v-stepper-step
                :complete="stepper > 4"
                complete-icon=""
                class="px-0 w-1/6 custom-stp-subs"
                step="4"
              >
                <div class="text-center stepper-title">
                  <div :style="stepper === 4 ? 'color: #6b4693' : ''">
                    <div>4</div>
                    <div>
                      {{ lang[getLanguage].PRODUCT_STEP_4 }}
                    </div>
                  </div>
                </div>
              </v-stepper-step>
              <v-divider class="custom-divider-dashed"></v-divider>

              <v-stepper-step
                :complete="stepper > 5"
                complete-icon=""
                class="px-0 w-1/6 custom-stp-subs"
                step="5"
              >
                <div class="text-center stepper-title">
                  <div :style="stepper === 5 ? 'color: #6b4693' : ''">
                    <div>5</div>
                    <div>
                      {{ lang[getLanguage].PRODUCT_STEP_5 }}
                    </div>
                  </div>
                </div>
              </v-stepper-step>
              <v-divider class="custom-divider-dashed"></v-divider>

              <v-stepper-step
                :complete="stepper > 6"
                complete-icon=""
                class="px-0 w-1/6 custom-stp-subs"
                step="6"
              >
                <div class="text-center stepper-title">
                  <div :style="stepper === 6 ? 'color: #6b4693' : ''">
                    <div>6</div>
                    <div>
                      {{ lang[getLanguage].PRODUCT_STEP_6 }}
                    </div>
                  </div>
                </div>
              </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1" class="px-2">
                <Step1
                  ref="stepOne"
                  :isMobileDevice="isMobileDevice"
                  :goToForm="goToForm"
                  :handleSubmitStepOne="handleSubmitStepOne"
                ></Step1>
              </v-stepper-content>
              <v-stepper-content step="2" class="px-2 md:px-6">
                <Step2
                  ref="stepTwo"
                  :goToForm="goToForm"
                  :handleSubmitStepTwo="handleSubmitStepTwo"
                ></Step2>
              </v-stepper-content>
              <v-stepper-content step="3" class="px-2 md:px-6">
                <Step3
                  ref="stepThree"
                  :goToForm="goToForm"
                  :handleSubmitStepThree="handleSubmitStepThree"
                ></Step3>
              </v-stepper-content>
              <v-stepper-content step="4" class="px-2 md:px-6">
                <Step4
                  ref="stepFour"
                  :goToForm="goToForm"
                  :handleSubmitStepFour="handleSubmitStepFour"
                ></Step4>
              </v-stepper-content>
              <v-stepper-content step="5" class="px-2 md:px-6">
                <Step5
                  ref="stepFive"
                  :goToForm="goToForm"
                  :handleSubmitStepFive="handleSubmitStepFive"
                ></Step5>
              </v-stepper-content>
              <v-stepper-content step="6" class="px-2 md:px-6">
                <Step6
                  ref="stepSix"
                  :goToForm="goToForm"
                  :handleSubmitStepSix="handleSubmitStepSix"
                ></Step6>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-layout>
      </v-layout>
      <!-- <canvas id="canvas"></canvas> -->
    </v-slide-y-transition>

    <v-dialog v-model="dialogDeposit" persistent max-width="650">
      <v-card class="p-4">
        <v-card-title class="text-2xl text-h5 font-bold font-montserrat">
          {{ getLanguage === "en" ? "Deposit Fee" : "Biaya Deposit" }}
        </v-card-title>
        <v-card-text class="font-work-sans text-sm font-normal">
          <p>
            {{
              getLanguage === "en"
                ? "This payment helps us to process your order. Deposit payments are fully refundable at the end of the subscription period."
                : "Pembayaran ini membantu kami untuk memproses pesanan Anda. Pembayaran deposit dapat dikembalikan sepenuhnya pada akhir periode berlangganan."
            }}
          </p>
          <div class="flex mb-4">
            <div class="w-5">1.</div>
            <div class="w-full">
              {{
                getLanguage === "en"
                  ? "By providing this deposit, the customer acknowledges the subscription order for the selected vehicle model."
                  : "Dengan memberikan deposit ini, pelanggan mengakui pesanan berlangganan untuk model kendaraan yang dipilih."
              }}
            </div>
          </div>

          <div class="flex mb-4">
            <div class="w-5">2.</div>
            <div class="w-full">
              {{
                getLanguage === "en"
                  ? "After the subscription period ends, the deposit will be returned to the customer (maximum 5 working days), when the vehicle has passed the inspection process by our team."
                  : "Setelah masa berlangganan berakhir, deposit akan dikembalikan kepada pelanggan (maksimal 5 hari kerja), ketika kendaraan telah melewati proses pemeriksaan oleh tim kami."
              }}
            </div>
          </div>
          <div class="flex mb-4">
            <div class="w-5">3.</div>
            <div class="w-full">
              {{
                getLanguage === "en"
                  ? "However, if damage is found to the vehicle, the refund of the deposit will be deducted according to the cost of repairs."
                  : "Namun jika ditemukan kerusakan pada kendaraan, maka pengembalian deposit akan dipotong sesuai biaya perbaikan."
              }}
            </div>
          </div>
        </v-card-text>
        <v-card-actions class="text-center">
          <v-btn
            class="mx-auto capitalize font-work-sans font-medium"
            depressed
            color="primary"
            @click="dialogDeposit = false"
          >
            {{ getLanguage === "en" ? "I Understand" : "Saya Mengerti" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOG LOADER -->
    <v-dialog v-model="isLoad" persistent width="300">
      <v-card color="white" class="p-3">
        <v-card-text class="text-center">
          <v-progress-circular
            color="primary"
            :size="50"
            indeterminate
          ></v-progress-circular>
        </v-card-text>
        <v-card-text class="text-center text-charged-grey-100">
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { getProfileApi } from "../../../../services/back-end-ruby";
import Step1 from "./Step1.vue";
import Step2 from "./Step2.vue";
import Step3 from "./Step3.vue";
import Step4 from "./Step4.vue";
import Step5 from "./Step5.vue";
import Step6 from "./Step6.vue";

export default {
  name: "form-subscribe",
  metaInfo() {
    return {
      title: this.metaTitle,
      meta: [
        { name: "description", content: this.metaDesc },
        { property: "og:title", content: this.metaTitle },
        { property: "og:og:image", content: this.metaImg },
        { property: "og:og:url", content: this.metaUrl },
        { property: "og:site_name", content: "Charged Asia" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
  props: {
    dark: Boolean,
    windowWidth: Number,
    isMobileDevice: Boolean,
    windowHeight: Number,
    index: Number,
    profile: Object,
  },
  computed: {
    getLanguage() {
      return this.$store.state.user.language;
    },
    getSessionUser() {
      return this.$store.getters.getSessionUser;
    },
    getDataStepDone() {
      return this.$store.getters.getDataStepDone;
    },
  },
  data() {
    return {
      isLoad: false,
      dialogDeposit: false,
      lang: this.$store.state.lang,
      stepper: 1,
      loadingScreen: false,
      alertDialog: false,
      alertText: "",
      alertStatus: "",
      loading: false,
    };
  },
  components: { Step1, Step2, Step3, Step4, Step5, Step6 },
  mounted() {},
  async created() {
    await this.getData();
  },
  methods: {
    async getData() {
      let token_api = this.$cookies.get("token_api");
      await getProfileApi(token_api).then((res) => {
        this.isLoad = true;
        // set subscription_id
        this.$store.commit("setIdSubs", res.data.subscription_id);
        this.$store.commit("setVehicleId", res.data?.vehicle_id ?? null);
        // Mapping existed datas
        // Informasi Pribadi
        const existedDetails = res.data.user;
        let spouseCompanyName = "";
        let spousePositionLabel = "";
        let spouseEmploymentStatus = "";
        let spouseYearOfExperience = "";
        let spouseSalary = "";

        if (existedDetails.profile.marital_status == "married") {
          let employeSpouse = existedDetails.profile.employments.find(
            (e) => e.employment_type == "spouse"
          );
          spouseCompanyName = employeSpouse.company_name || "";
          spousePositionLabel = employeSpouse.job_position || "";
          spouseEmploymentStatus = employeSpouse.employment_status || "";
          spouseYearOfExperience = employeSpouse.length_of_work || "";
          spouseSalary = employeSpouse.profit_income || "";
        }

        const employeeUser = existedDetails.profile.employments.find(
          (e) => e.employment_type == "user"
        );

        this.$store.commit("setDataInformasiPribadi", {
          name: existedDetails.full_name || "",
          nik: existedDetails.profile.nik || "",
          emailPribadi: existedDetails.email || "",
          phoneNumberPribadi: existedDetails.phone_number || "",
          tempatLahir: existedDetails.profile.birth_place || "",
          dateTglLahir: existedDetails.profile.birth_date || "",
          motherName: existedDetails.profile.guardian_name || "",
          companyName: employeeUser?.company_name || "",
          yearOfExperience: employeeUser?.length_of_work || "",
          salary: employeeUser?.profit_income || "",
          maritalStatus: existedDetails?.profile?.marital_status || "",
          jobPosition: employeeUser?.job_position || "",
          employmentStatus: employeeUser?.employment_status || "",
          creditCardLimit: employeeUser?.credit_card_limit || "",
          spouseCompanyName,
          spousePositionLabel,
          spouseEmploymentStatus,
          spouseYearOfExperience,
          spouseSalary,
        });

        // Domisili
        const currentAddress = existedDetails.profile.addresses.find(
          (a) => a.address_type == "current_address"
        );
        const ktp_address = existedDetails.profile.addresses.find(
          (a) => a.address_type == "id_address"
        );

        this.$store.commit("setDataDomisili", {
          serviceProvince: currentAddress.province || "",
          serviceCity: currentAddress.city || "",
          serviceDistrict: currentAddress.district || "",
          serviceSubDistrict: currentAddress.sub_district || "",
          address: currentAddress.address_street || "",
          serviceZip: currentAddress.postal_code || "",
          homeOwnershipStatus: currentAddress.ownership_status || "",
          lengthOfStay: currentAddress.length_of_stay || "",
          sameAddress: res.data.user.profile.addresses
            .find((s) => s.address_type == "current_address")
            .sub_district.includes(
              res.data.user.profile.addresses.find(
                (s) => s.address_type == "id_address"
              )?.sub_district
            ),
          provinceKtp: ktp_address?.province || "",
          cityKtp: ktp_address?.city || "",
          districtKtp: ktp_address?.district || "",
          subDistrictKtp: ktp_address?.sub_district || "",
          addressKtp: ktp_address?.address_street || "",
          postalCodeKtp: ktp_address?.postal_code || "",
        });

        // Subscription Plan
        this.$store.commit("setDataPlanSubs", {
          bankName: existedDetails.profile.bank_account?.bank_name || "",
          accountBankNumber:
            existedDetails.profile.bank_account?.account_number || "",
          accountHoldersName:
            existedDetails.profile.bank_account?.holder_name || "",
          plansQty: "",
        });
      });
      this.isLoad = false;
    },
    closeAlertDialog() {
      this.alertDialog = false;
    },
    goToForm(to) {
      this.stepper = to;
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    async handleSubmitStepOne() {
      this.isLoad = true;
      await this.$refs.stepOne.submit();
      if (this.getDataStepDone) {
        await this.$refs.stepTwo.getData();
        this.isLoad = false;
        this.goToForm(2);
        this.$refs.stepTwo.scrollToTop();
      } else {
        this.$store.commit("setDataStepDone", false);
      }
    },
    async handleSubmitStepTwo() {
      this.isLoad = true;
      await this.$refs.stepTwo.submit();
      if (this.getDataStepDone) {
        await this.$refs.stepThree.getData();
        this.isLoad = false;
        this.goToForm(3);
        this.$refs.stepThree.scrollToTop();
      }
      this.$store.commit("setDataStepDone", false);
    },
    async handleSubmitStepThree() {
      this.isLoad = true;
      await this.$refs.stepThree.submit();
      if (this.getDataStepDone) {
        await this.$refs.stepFour.getData();
        this.isLoad = false;
        this.goToForm(4);
        this.$refs.stepFour.scrollToTop();
      }
      this.$store.commit("setDataStepDone", false);
    },
    async handleSubmitStepFour() {
      this.isLoad = true;
      await this.$refs.stepFour.submit();
      if (this.getDataStepDone) {
        await this.$refs.stepFive.getData();
        this.isLoad = false;
        this.goToForm(5);
        this.$refs.stepFive.scrollToTop();
      }
      this.$store.commit("setDataStepDone", false);
    },
    async handleSubmitStepFive() {
      this.isLoad = true;
      await this.$refs.stepFive.submit();
      if (this.getDataStepDone) {
        await this.$refs.stepSix.getData();
        this.isLoad = false;
        this.goToForm(6);
        this.$refs.stepSix.scrollToTop();
      }
      this.$store.commit("setDataStepDone", false);
    },
    async handleSubmitStepSix() {
      this.isLoad = true;
      await this.$refs.stepSix.submit();
      this.isLoad = false;
    },
  },
};
</script>

<style>
.custom-mounted-file-btn {
  padding: 16px;
  display: flex;
  border-radius: 4px;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #c1c2c4;
  background-color: transparent;
}

.v-stepper__header > .custom-divider-dashed {
  border-style: dashed;
  margin-left: -80px !important;
  margin-right: -80px !important;
}

.v-stepper__header > .v-stepper__step--active > .v-stepper__step__step {
  box-shadow: 0 0 16px 0 #6b4693;
}

.v-stepper__step__step.primary > i {
  color: #262626 !important;
  font-size: 5px !important;
}

/* .v-stepper__header > .v-stepper__step > .v-stepper__step__step {
  background-color: white !important;
  color: white !important;
  border: 1px solid #858688 !important;
  margin: 4px 0 8px 0;
  min-width: 16px;
  height: 16px;
  width: 16px;
}

.v-stepper__header > .v-stepper__step--active > .v-stepper__step__step {
  color: #262626 !important;
  background-color: #262626 !important;
  box-shadow: 0 0 16px 0 #6b4693;
}

.v-stepper__header > .v-stepper__step--complete > .v-stepper__step__step {
  color: #262626 !important;
  background-color: #262626 !important;
}

.v-stepper__header > .v-application .primary {
  background-color: white !important;
  border: 1px solid #858688 !important;
} */

.stepTitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #353638;
}

.sectionStepTitle {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #353638;
}

.stepTitleSmall {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.02em;
  color: #353638;
}

.radio-selection {
  width: 100%;
  background-color: #fafafa !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  margin: 0.5rem 0;
}

.radio-selection-shipping {
  width: 100%;
  background-color: white !important;
}

.radio-selection-color {
  border: 2px solid #e8e8e8;
  border-radius: 50%;
  margin-right: 0.5rem;
}

.selected-selection {
  border: 2px solid !important;
  border-color: #6b4693 !important;
  border-radius: 12px;
}

.radio-button {
  padding: 0.5rem;
}

.customization-item-title {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #353638;
}

.customization-item-price {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #535456;
}

.customization-item-desc {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #858688;
}

.continue-button {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #ffffff;
}

.personal-input-title {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #858688;
}

.personal-input-value {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #353638;
}

.personal-input-value.v-input--is-disabled
  .v-input__control
  > .v-input__slot
  fieldset {
  background: rgba(0, 0, 0, 0.12) !important;
}

.custom-d-input.v-input--is-disabled
  .v-input__control
  > .v-input__slot
  fieldset {
  background: rgba(0, 0, 0, 0.12) !important;
}

.personal-item-label {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
}

.edit-button {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: -0.02em;
  text-decoration-line: underline;
  color: #6b4693;
}

.review-order-item-label {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #353638;
}

.pricing-item.v-card--link:before {
  background: none;
}

.booking-fee {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #c1c2c4;
  padding-bottom: 1rem;
  align-items: center;
}

.booking-fee-title {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  align-content: flex-end;
}

.booking-fee-amount {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  align-content: flex-end;
}

.booking-fee-title span {
  color: #858688;
  font-size: 14px;
}

.booking-fee-amount span {
  color: #858688;
  font-size: 14px;
  font-weight: 600;
}

.booking-fee-amount-title {
  color: #858688;
  font-size: 14px;
  font-weight: 600;
}

.stepBack {
  color: #6b4693;
  font-size: 16px;
  font-weight: 500;
  padding: 2rem 0;
}

.text-info {
  margin-bottom: 6px !important;
  font-size: 16px;
  font-weight: 400;
}

.text-info-shipping {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 2rem;
}

.text-title {
  margin: 1rem 0;
}

.text-title-subscription {
  margin-top: 2rem;
}

.pricing-item {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 230px;
  padding: 1rem;
  border-radius: 12px !important;
}

.pricing-item-title {
  font-size: 20px;
  font-weight: 700;
  color: #262626;
  word-wrap: break-word !important;
}

.pricing-item-price {
  font-size: 18px;
  font-weight: 400;
  color: #535456;
  word-wrap: break-word !important;
}

.pricing-item-billed {
  font-size: 16px;
  font-weight: 400;
  color: #858688;
  word-wrap: break-word !important;
}

.pricing-item-bonus {
  font-size: 18px;
  font-weight: 500;
  color: #6b4693;
  word-wrap: break-word !important;
}

.pricing-item-allprice {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  word-wrap: break-word !important;
}

.pricing {
  display: flex;
  /* flex-direction: row; */
  /* justify-content: space-evenly; */
  /* align-items: center; */
}

.pricing-card {
  width: 200px;
}
.subscription-detail {
  margin: 1rem 0;
  padding-bottom: 1rem;
  border-bottom: 1px solid #c1c2c4;
}

.your-order {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}

.your-order p {
  margin-bottom: 0.5rem;
}

.customer-information {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  font-size: 16px;
  font-weight: 400;
}

.customer-information-title {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #858688;
}

.customer-information-name {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #262626;
}

.customer-information-email {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #535456;
}

.customer-information-phone {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #535456;
}

.loading-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.alert-dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.succes-icon {
  font-size: 36px !important;
  color: #4fbd6d !important;
}

.failed-icon {
  font-size: 36px !important;
  color: #cb3a31 !important;
}

.selection {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.radio-button {
  padding: 0.5rem;
}

.theme--light.v-icon {
  color: rgba(0, 0, 0, 0.54);
}

.style-file-input.v-file-input {
  position: absolute;
  width: 100%;
  height: 100%;
  /* display: none; */
  top: 10px;
  z-index: 1;
}
.style-file-input.v-file-input .v-input__slot,
.v-input__prepend-outer {
  position: absolute;
  width: 100%;
  height: 100%;
  display: none;
  top: 10px;
  z-index: 1;
}
.style-file-input.v-text-field > .v-input__control > .v-input__slot:before {
  border: none;
}
.style-file-input.v-file-input .v-text-field__details {
  position: absolute;
  bottom: -5px;
  z-index: 1;
}

.font-family-work-sans {
  font-family: "Work Sans";
}

.customization-item-title {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #353638;
}

.customization-item-price {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #535456;
}

.customization-item-desc {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #858688;
}

.continue-button {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #ffffff;
}

.personal-input-title {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #858688;
}

.personal-input-value {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #353638;
}

.personal-input-value.v-input--is-disabled
  .v-input__control
  > .v-input__slot
  fieldset {
  background: rgba(0, 0, 0, 0.12) !important;
}

.custom-d-input.v-input--is-disabled
  .v-input__control
  > .v-input__slot
  fieldset {
  background: rgba(0, 0, 0, 0.12) !important;
}

.personal-item-label {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
}
.edit-button {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: -0.02em;
  text-decoration-line: underline;
  color: #6b4693;
}
.review-order-item-label {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #353638;
}

.pricing-item.v-card--link:before {
  background: none;
}

@media (max-width: 575.98px) {
  .v-stepper--alt-labels .custom-stp-subs.v-stepper__step {
    flex-basis: auto;
  }

  .custom-stp-subs ~ .v-divider {
    max-width: 10%;
  }

  .v-stepper__step__step.primary > i {
    font-style: normal !important;
    font-size: 14px !important;
    margin-top: -3px;
    color: white !important;
  }
}

@media (min-width: 575px) {
  .v-stepper__header > .v-stepper__step > .v-stepper__step__step {
    background-color: white !important;
    color: white !important;
    border: 1px solid #858688 !important;
    margin: 4px 0 8px 0;
    min-width: 16px;
    height: 16px;
    width: 16px;
  }

  .v-stepper__header > .v-stepper__step--active > .v-stepper__step__step {
    color: #262626 !important;
    background-color: #262626 !important;
    box-shadow: 0 0 16px 0 #6b4693;
  }

  .v-stepper__header > .v-stepper__step--complete > .v-stepper__step__step {
    color: #262626 !important;
    background-color: #262626 !important;
  }

  .v-stepper__header > .v-application .primary {
    background-color: white !important;
    border: 1px solid #858688 !important;
  }
}
</style>