<template>
  <div class="pb-10">
    <v-row>
      <v-col cols="12" md="10">
        <p class="font-montserrat text-2xl font-bold text-charged-grey-200">
          {{
            getLanguage == "en" ? "Customization Product" : "Kostumisasi Produk"
          }}
        </p>
        <!-- START SECTION OPSI KENDARAAN -->
        <p
          class="font-work-sans text-base font-semibold text-charged-grey-200 mt-8"
        >
          {{
            getLanguage == "en"
              ? "Pilih Model Kendaraan"
              : "Pilih Model Kendaraan"
          }}
        </p>
        <v-radio-group v-model="selectedBike" mandatory>
          <v-row class="md:ml-0.5">
            <v-col
              v-for="(item, index) in getListVehicle"
              :key="index + 'bike'"
              cols="12"
              sm="4"
            >
              <v-card
                outlined
                @click="selectBike(item)"
                :class="
                  selectedBike == item ? classSelectedBike : classNoSelectedBike
                "
                style="position: relative"
              >
                <div
                  v-if="item.stock == 0"
                  class="absolute top-4 bg-red-400 px-2 z-10 shadow-md font-medium"
                  style="border-radius: 0 10px 10px 0 !important"
                >
                  {{ getLanguage == "en" ? "Out of Stock" : "Stok habis" }}
                </div>
                <div class="p-2 md:hidden pt-3">
                  <v-img
                    class="mx-auto"
                    max-height="100"
                    max-width="150"
                    :src="item.image"
                  ></v-img>
                </div>
                <div class="p-2 hidden md:block">
                  <v-img
                    class="mx-auto"
                    max-height="150"
                    max-width="250"
                    :src="item.image"
                  ></v-img>
                </div>
                <p
                  class="text-center font-work-sans text-lg font-semibold text-charged-grey-100 md:mt-3"
                >
                  {{ item.name }}
                </p>
                <p
                  class="text-center font-work-sans text-base font-normal text-charged-grey-300 -mt-3"
                >
                  {{ "Rp" + formatPrice(item.dailyPrice) + "/hari" }}
                </p>
                <div class="flex justify-center">
                  <v-radio :disabled="item.stock == 0" :value="item"></v-radio>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-radio-group>
        <!-- END SECTION OPSI KENDARAAN -->

        <!-- START SECTION OPSI BATERAI -->
        <div v-if="getListBatteryVehicle?.length > 0">
          <p
            class="font-work-sans text-base font-semibold text-charged-grey-200 mt-8"
          >
            {{ getLanguage == "en" ? "Jumlah Baterai" : "Jumlah Baterai" }}
            <span
              class="font-work-sans text-base font-normal text-charged-grey-400"
            >
              ({{
                getLanguage == "en"
                  ? "Harga termasuk PPN"
                  : "Harga termasuk PPN"
              }})
            </span>
          </p>
          <v-radio-group v-model="selectedBattery" mandatory>
            <div
              class="ml-0.5 flex items-center mb-5"
              v-for="(item, index) in getListBatteryVehicle"
              :key="index + 'batterai'"
            >
              <div class="w-10">
                <div class="flex justify-center">
                  <v-radio :value="item"></v-radio>
                </div>
              </div>
              <div class="w-full">
                <v-card
                  elevation="0"
                  @click="selecteBattery(item)"
                  color="#F5F4F6"
                  :class="
                    selectedBattery == item
                      ? classSelectedBatteryNCharger
                      : classNoSelectedBatteryNCharger
                  "
                >
                  <v-row align="center">
                    <v-col cols="9">
                      <span
                        class="font-work-sans text-base font-medium text-charged-grey-200 block"
                      >
                        {{ item.title.id }}
                      </span>
                      <span
                        class="font-work-sans text-sm font-normal block text-charged-grey-400"
                      >
                        {{ item.desc.id }}
                      </span>
                    </v-col>
                    <v-col class="flex justify-end">
                      <span
                        class="font-work-sans text-base font-normal text-charged-grey-300"
                      >
                        + Rp
                        {{
                          formatPrice(item.price) +
                          "/" +
                          lang[getLanguage].LABEL_DAY
                        }}
                      </span>
                    </v-col>
                  </v-row>
                </v-card>
              </div>
            </div>
          </v-radio-group>
        </div>
        <!-- END SECTION OPSI BATERAI -->

        <!-- START SECTION OPSI CHARGER -->
        <div v-if="getListChargersVehicle?.length > 0">
          <p
            class="font-work-sans text-base font-semibold text-charged-grey-200 mt-8"
          >
            {{ getLanguage == "en" ? "Tipe Charger" : "Tipe Charger" }}
            <span
              class="font-work-sans text-base font-normal text-charged-grey-400"
            >
              ({{
                getLanguage == "en"
                  ? "Harga termasuk PPN"
                  : "Harga termasuk PPN"
              }})
            </span>
          </p>
          <v-radio-group v-model="selectedCharger" mandatory>
            <div
              class="ml-0.5 flex items-center mb-5"
              v-for="(item, index) in getListChargersVehicle"
              :key="index + 'charger'"
            >
              <div class="w-10">
                <div class="flex justify-center">
                  <v-radio :value="item"></v-radio>
                </div>
              </div>
              <div class="w-full">
                <v-card
                  elevation="0"
                  @click="selecteCharger(item)"
                  color="#F5F4F6"
                  :class="
                    selectedCharger == item
                      ? classSelectedBatteryNCharger
                      : classNoSelectedBatteryNCharger
                  "
                >
                  <v-row align="center">
                    <v-col cols="9">
                      <span
                        class="font-work-sans text-base font-medium text-charged-grey-200 block"
                      >
                        {{ item.title }}
                        <span
                          class="font-work-sans text-base font-normal text-charged-grey-400"
                        >
                          {{ item.time.id }}
                        </span>
                      </span>
                      <span
                        class="font-work-sans text-sm font-normal block text-charged-grey-400"
                      >
                        {{ item.description.id }}
                      </span>
                    </v-col>
                    <v-col class="flex justify-end">
                      <span
                        class="font-work-sans text-base font-normal text-charged-grey-300"
                      >
                        + Rp
                        {{
                          formatPrice(item.price) +
                          "/" +
                          lang[getLanguage].LABEL_DAY
                        }}
                      </span>
                    </v-col>
                  </v-row>
                </v-card>
              </div>
            </div>
          </v-radio-group>
        </div>
        <!-- END SECTION OPSI CHARGER -->

        <!-- START SECTION OPSI REAR -->
        <div v-if="getListAksesorisVehicle?.length > 0">
          <p
            class="font-work-sans text-base font-semibold text-charged-grey-200 mt-8"
          >
            {{
              getLanguage == "en"
                ? "Additional Accessories"
                : "Tambahan Aksesoris"
            }}
          </p>
          <v-radio-group v-model="selectedRear" mandatory>
            <div
              class="ml-0.5 flex items-center mb-5"
              v-for="(item, index) in getListAksesorisVehicle"
              :key="index + 'rear'"
            >
              <div class="w-10">
                <div class="flex justify-center">
                  <v-radio :value="item"></v-radio>
                </div>
              </div>
              <div class="w-full">
                <v-card
                  elevation="0"
                  @click="selecteRear(item)"
                  color="#F5F4F6"
                  :class="
                    selectedRear == item
                      ? classSelectedBatteryNCharger
                      : classNoSelectedBatteryNCharger
                  "
                >
                  <v-row align="center">
                    <v-col cols="9">
                      <span
                        class="font-work-sans text-base font-medium text-charged-grey-200 block"
                      >
                        {{ item.title.id }}
                        <!-- <span
                          class="font-work-sans text-base font-normal text-charged-grey-400"
                        >
                          {{ item.time.id }}
                        </span> -->
                      </span>
                      <span
                        class="font-work-sans text-sm font-normal block text-charged-grey-400"
                      >
                        {{ item.description }}
                      </span>
                    </v-col>
                    <v-col class="flex justify-end">
                      <span
                        class="font-work-sans text-base font-normal text-charged-grey-300"
                      >
                        + Rp
                        {{
                          formatPrice(item.price) +
                          "/" +
                          lang[getLanguage].LABEL_DAY
                        }}
                      </span>
                    </v-col>
                  </v-row>
                </v-card>
              </div>
            </div>
          </v-radio-group>
        </div>
        <!-- END SECTION OPSI REAR -->
      </v-col>
    </v-row>
    <span class="w-full border border-dashed inline-block"></span>
    <!-- Mobile -->
    <div class="flex justify-end mt-3 md:hidden">
      <v-btn
        class="capitalize font-work-sans font-medium text-base rounded-lg"
        color="primary"
        block
        :disabled="getListBatteryVehicle.length == 0"
        :loading="loadingBtnStep"
        @click="stepOneComplete"
      >
        Lanjutkan
        <v-icon small>mdi-chevron-right</v-icon>
      </v-btn>
    </div>
    <!-- Dekstop -->
    <div class="md:flex justify-end mt-3 hidden">
      <v-btn
        class="capitalize font-work-sans font-medium text-base rounded-lg"
        color="primary"
        right
        :disabled="getListBatteryVehicle.length == 0 || loadingBtnStep"
        :loading="loadingBtnStep"
        @click="stepOneComplete"
      >
        Lanjutkan
        <v-icon small>mdi-chevron-right</v-icon>
      </v-btn>
    </div>

    <!-- DIALOG LOADER -->
    <v-dialog v-model="isLoad" hide-overlay persistent max-width="300">
      <v-card color="white" class="p-3">
        <v-card-text class="text-center">
          <v-progress-circular
            color="primary"
            :size="50"
            indeterminate
          ></v-progress-circular>
        </v-card-text>
        <v-card-text class="text-center text-charged-grey-100">
          Please stand by
          <!-- <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear> -->
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { postCustomizationApi } from "../../../../services/back-end-ruby/Vehicle";
export default {
  name: "form-subs-step-1",
  metaInfo() {
    return {
      title: this.metaTitle,
      meta: [
        { name: "description", content: this.metaDesc },
        { property: "og:title", content: this.metaTitle },
        { property: "og:og:image", content: this.metaImg },
        { property: "og:og:url", content: this.metaUrl },
        { property: "og:site_name", content: "Charged Asia" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
  props: {
    dark: Boolean,
    windowWidth: Number,
    isMobileDevice: Boolean,
    windowHeight: Number,
    index: Number,
    profile: Object,
    step: Number,
    stepOneComplete: Function,
  },
  data() {
    return {
      loadingScreen: false,
      isLoad: false,
      stepDone: false,
      loadingBtnStep: false,
      selectedBike: null,
      selectedBattery: null,
      selectedCharger: null,
      selectedRear: null,
      selectedColor: null,
      classSelectedBike:
        "md:py-10 selected-selection shadow-md shadow-charged-purple-300 rounded-lg w-full h-60 md:h-full mx-auto",
      classNoSelectedBike:
        "md:py-10 no-selected-selection rounded-lg w-60 w-full h-60 md:h-full mx-auto border border-1",
      classSelectedBatteryNCharger: "p-3 selected-selection rounded-lg",
      classNoSelectedBatteryNCharger: "p-3 rounded-lg",
      lang: this.$store.state.lang,
    };
  },
  watch: {
    windowWidth() {
      if (this.windowWidth < 768) {
        this.showBottomNav = false;
      } else {
        this.showBottomNav = false;
      }
    },
  },
  computed: {
    getLanguage() {
      return this.$store.state.user.language;
    },
    getUser() {
      return this.$store.state.user;
    },
    getServiceCity() {
      return this.$store.state.user.serviceCity;
    },
    getProfile() {
      return this.$store.getters.getProfile;
    },
    getListVehicle() {
      return this.$store.getters.getListVehicle;
    },
    getListBatteryVehicle() {
      return this.$store.getters.getListBatteryVehicle;
    },
    getListChargersVehicle() {
      return this.$store.getters.getListChargersVehicle;
    },
    getListAksesorisVehicle() {
      return this.$store.getters.getListAksesorisVehicle;
    },
    getListColorsVehicle() {
      return this.$store.getters.getListColorsVehicle;
    },
  },
  mounted() {
    // this.selectBike(this.getListVehicle[1]);
  },
  async created() {
    // this.isLoad = true;
    // this.loadingScreen = true;
    await this.selectBike(this.getListVehicle[2]);
    // this.isLoad = false;
    // this.loadingScreen = false;
    // setTimeout(() => {
    // }, 500);
  },
  methods: {
    async selectBike(item) {
      this.isLoad = true;
      if (item.stock > 0) {
        this.selectedBike = item;
        let token = this.$cookies.get("token_api");
        await this.$store.dispatch("setVehicle", {
          token,
          vehicle_id: item.vehicle_id,
          mitra_driver_gojek: true,
          refresh: true,
        });
        this.selecteBattery(this.getListBatteryVehicle[0]);
        this.selecteCharger(this.getListChargersVehicle[0]);
        this.selecteRear(this.getListAksesorisVehicle[0] ?? null);
        this.selecteColor(this.getListColorsVehicle[0]);
        // setTimeout(() => {
        // }, 500);
        this.isLoad = false;
        this.$cookies.remove("v_s");
        // simpan vehicle dan id subcribe untuk sinkronisasi
        this.$cookies.set("v_s", this.getProfile.id + "." + item.vehicle_id);
      }
      // console.log("SELECTED_BIKE", this.selectedBike);
    },
    selecteBattery(item) {
      this.selectedBattery = item;
      // console.log("SELECTED_BATTERY", this.selectedBattery);
    },
    selecteCharger(item) {
      this.selectedCharger = item;
      // console.log("SELECTED_CHARGER", this.selectedCharger);
    },
    selecteRear(item) {
      this.selectedRear = item;
      // console.log("SELECTED_REAR", this.selectedRear);
    },
    selecteColor(item) {
      this.selectedColor = item;
      // console.log("SELECTED_COLOR", this.selectedColor);
    },
    async submit() {
      // console.log("SUBMIT_KOSTUMISASI");
      this.loadingBtnStep = true;
      let token = this.$cookies.get("token_api");
      await postCustomizationApi(token, {
        // vehicle_color: "5ae3763c-beda-4577-b991-307687138d58",
        vehicle_color: this.selectedColor.id,
        charger: this.selectedCharger.id,
        battery: this.selectedBattery.id,
        rearbox: "",
        vehicle_id: this.selectedBike.vehicle_id,
        subscription: this.$store.getters.getIdSubs,
      }).then((res) => {
        // console.log("RESULT_STEP_1", res);
        if (res.meta.code == 200) {
          this.loadingBtnStep = false;
          this.$store.commit("setDataGojekStepDone", true);
          this.$store.commit("setDataGojekKostumisasi", {
            bike: this.selectedBike,
            qtyBattery: this.selectedBattery,
            typeCharge: this.selectedCharger,
            accessories: this.selectedRear,
            color: this.selectedColor,
          });
        } else {
          this.$store.commit("setDataGojekStepDone", false);
        }
      });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>

<style>
.v-card--link:before {
  background: white !important;
}

.no-selected-selection {
  border: 2px solid !important;
  border-color: #c1c2c4 !important;
  border-radius: 12px;
}
</style>