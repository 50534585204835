export const formatPrice = (value) => {
  let val = (value / 1).toFixed(0).replace(".", ",");
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export const formatDate = (date) => {
  if (!date) return null;

  const [year, month, day] = date.split("-");
  return `${day}-${month}-${year}`;
}