<template>
  <div>
    <v-layout id="home" column>
      <!-- SLIDER HERO BANNER -->
      <v-carousel
        v-show="$vuetify.breakpoint.lgAndUp"
        cycle
        style="border-radius: 0px"
        dark
        :width="'100%'"
        :height="'100%'"
        :min-height="windowHeight"
        hide-delimiters
        hide-delimiter-background
        :show-arrows="true"
        :interval="7000"
      >
        <!-- <a @click="scrollToAnchorPoint('gotobikeshome')">
                <v-carousel-item class="bg-lebaran" cover :min-height="windowHeight" :max-height="windowHeight">
                </v-carousel-item>
              </a> -->

        <!-- <a @click="scrollToAnchorPoint('gotobikeshome')">
          <v-carousel-item
            class="bg-promo"
            cover
            :min-height="windowHeight"
            :max-height="windowHeight"
          >
          </v-carousel-item>
        </a> -->

        <v-carousel-item
          class="bg-home"
          cover
          :min-height="windowHeight"
          :max-height="windowHeight"
        >
          <v-layout column style="margin-left: 100px; margin-top: 250px">
            <span
              class="homeTitleNew white--text"
              style="margin-bottom: 8px; letter-spacing: -2.04px"
              >{{ lang[getLanguage].SLOGAN }}</span
            >
            <span
              class="work-sans white--text"
              style="
                font-size: 20px;
                line-height: 28px;
                max-width: 680px;
                letter-spacing: -0.4px;
              "
              >{{ lang[getLanguage].SLOGAN_DESCRIPTION }}
            </span>
            <!-- <v-btn
              color="#6B4693"
              large
              :to="'/testride/home'"
              class="work-sans font-medium text-base"
              :style="
                windowWidth < 770
                  ? 'width:100%; color:#FFF; text-transform: none !important; border-radius: 8px; margin-top: 10px; letter-spacing: -0.32px; font-size: 16px !important; height: 52px;'
                  : 'width:300px; color:#FFF; text-transform: none !important; border-radius: 8px; margin-top: 24px; letter-spacing: -0.32px; font-size: 16px !important; height: 52px;'
              "
            >
              {{ lang[getLanguage].TEST_RIDE_SHOWROOM }}
              <v-icon right style="color: #535456; font-size: 20px">
                mdi-chevron-right
              </v-icon>
            </v-btn> -->
          </v-layout>
        </v-carousel-item>
      </v-carousel>

      <v-carousel
        v-show="$vuetify.breakpoint.mdAndDown"
        cycle
        style="border-radius: 0px"
        dark
        :width="'100%'"
        :height="'100%'"
        :min-height="windowHeight"
        hide-delimiters
        hide-delimiter-background
        :show-arrows="true"
      >
        <!-- <a @click="scrollToAnchorPoint('gotobikeshome')">
                <v-carousel-item class="bg-lebaran-mobile" cover>
                </v-carousel-item>
              </a> -->

        <!-- <a @click="scrollToAnchorPoint('gotobikeshome')">
          <v-carousel-item class="bg-promo-mobile" cover> </v-carousel-item>
        </a> -->

        <v-carousel-item
          class="bg-home-mobile"
          cover
          :min-height="windowHeight"
          :max-height="windowHeight"
        >
          <v-layout
            column
            align-center
            style="margin-top: 90px; margin-left: 5%; margin-right: 5%"
          >
            <Link
              style="margin: 0; padding: 0"
              :text="'Powered by VMOTOSOCO'"
              class="white--text mb-12"
              actionParam="https://en.vmotosoco.com/"
              @actions="goToLink"
            />
            <span
              class="homeTitleNewMobile white--text text-4xl mb-2"
              style="line-height: 45px; letter-spacing: -1.08px"
              >{{ lang[getLanguage].SLOGAN }}</span
            >
            <span
              style="
                font-size: 14px;
                color: #fff;
                letter-spacing: -0.28px;
                font-weight: 400;
              "
              class="work-sans font-normal text-center white--text mb-6"
              >{{ lang[getLanguage].SLOGAN_DESCRIPTION }}
            </span>
            <!-- <v-btn
              color="#6B4693"
              large
              :to="'/testride/home'"
              class="work-sans"
              :style="
                windowWidth < 770
                  ? 'width:100%;color:#FFF;text-transform: none !important; border-radius: 8px; margin-top: 0px;'
                  : 'width:300px;color:#FFF;text-transform: none !important; border-radius: 8px; margin-top: 0px;'
              "
            >
              {{ lang[getLanguage].TEST_RIDE_SHOWROOM }}
              <v-icon right style="color: #535456; font-size: 14px">
                mdi-chevron-right
              </v-icon>
            </v-btn> -->
            <p class="mt-2"></p>
          </v-layout>
        </v-carousel-item>
      </v-carousel>
    </v-layout>
    <DialogCheckServiceAre
      ref="dialogCheckServiceArea"
      :bikeName="null"
      :dark="dark"
      :drawer="drawer"
      :isMobileDevice="isMobileDevice"
      :windowWidth="windowWidth"
      :fullScreen="fullScreen"
      :btnCloseCustom="true"
    >
      <template slot="btnCloseCustom">
        <v-btn @click="dialogClose" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </DialogCheckServiceAre>
  </div>
</template>
<script>
import DialogCheckServiceAre from "./components/DialogCheckServiceAre.vue";
export default {
  components: { DialogCheckServiceAre },
  name: "sign-mitra-gojek",

  props: {
    dark: Boolean,
    windowWidth: Number,
    windowHeight: Number,
    isMobileDevice: Boolean,
    drawer: Boolean,
  },

  computed: {
    getLanguage() {
      return this.$store.state.user.language;
    },
  },

  data() {
    return {
      fullScreen: false,
      lang: this.$store.state.lang,
    };
  },

  created() {},

  mounted() {
    this.start();
  },

  methods: {
    start() {
      // console.log("THIS_ROUTE", this.$route.params.id);
      if (
        this.$route.params.id !==
        "referrer=dEFMT1dvM09HTkV5UlF6Q1p1cDk2d3R0&source=Gojek"
      ) {
        this.$router.push("/");
      } else {
        this.$store.commit("setCustomerType", "mitra_driver_gojek");
      }
      this.$refs.dialogCheckServiceArea.checkServiceAreaDialogOpen();
      // console.log(this.$refs.dialogCheckServiceArea);
    },
    dialogClose() {
      this.$refs.dialogCheckServiceArea.checkServiceAreaDialogClose();
      this.$router.push("/");
    },
    goToLink(link) {
      window.open(link, "_blank");
    },
  },
};
</script>