<template>
  <div>
    <v-layout column v-if="loading">
      <p class="mt-0"></p>

      <v-layout column>
        <v-spacer></v-spacer>
        <v-skeleton-loader
          class="mx-auto"
          type="card"
          width="100%"
        ></v-skeleton-loader>
        <v-spacer></v-spacer>
      </v-layout>
    </v-layout>
    <v-row v-else>
      <v-col cols="12" md="8">
        <v-col class="stepBack pt-0">
          <v-btn
            class="pl-0 md:pl-4"
            @click.stop="goToForm(2)"
            text
            color="primary"
            :ripple="false"
            style="font-weight: 600"
          >
            <v-icon color="primary" class="mr-1">mdi-chevron-left</v-icon
            >{{ lang[getLanguage].BUT_BACK }}
          </v-btn>
        </v-col>
        <v-card flat mb-4>
          <v-col col="12" md="12" class="text-title">
            <v-row>
              <span id="stepTitle" class="stepTitle">{{
                lang[getLanguage].TEST_DRIVE_ADDRESS_TITLE
              }}</span>
            </v-row>
          </v-col>

          <!-- START RESIDENCE ADDRESS -->
          <v-row class="mt-3">
            <v-col cols="12" md="6" class="py-0">
              <span
                class="text-base font-normal font-work-sans text-charged-grey-400"
                >{{ lang[getLanguage].TEST_DRIVE_PROVINCE }}</span
              >
              <v-select
                class="custom-combobox-d"
                style="width: 100%"
                outlined
                dense
                filled
                v-model="serviceProvince"
                :items="getProvince"
                item-text="name"
                @change="changeProvince"
                :disabled="serviceProvince !== ''"
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <span
                class="text-base font-normal font-work-sans text-charged-grey-400"
                >{{ lang[getLanguage].TEST_DRIVE_CITY }}</span
              >
              <v-select
                class="custom-combobox-d"
                style="width: 100%"
                outlined
                dense
                filled
                v-model="serviceCity"
                :items="
                  getCities.filter((res) => res.province == serviceProvince)
                "
                item-text="name"
                :disabled="serviceCity !== ''"
                @change="changeCity"
              >
              </v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6" class="py-0">
              <span
                class="text-base font-normal font-work-sans text-charged-grey-400"
                >{{ lang[getLanguage].TEST_DRIVE_DISCTRICT }}</span
              >
              <v-select
                class="custom-combobox-d"
                style="width: 100%"
                outlined
                dense
                filled
                v-model="serviceDistrict"
                :items="getRegencies.filter((res) => res.city == serviceCity)"
                :disabled="serviceDistrict !== ''"
                item-text="name"
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <span
                class="text-base font-normal font-work-sans text-charged-grey-400"
                >{{ lang[getLanguage].TEST_DRIVE_SUB_DISCTRICT }}</span
              >
              <v-select
                class="custom-combobox-d"
                style="width: 100%"
                outlined
                dense
                filled
                v-model="serviceSubDistrict"
                :items="
                  getServiceCities.filter(
                    (res) => res.regency == serviceDistrict
                  )
                "
                item-id="id"
                item-text="village"
                :disabled="serviceSubDistrict !== ''"
                @change="changeSubDistrict"
              >
              </v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="py-0">
              <span
                class="text-base font-normal font-work-sans text-charged-grey-400"
                >{{ lang[getLanguage].TEST_DRIVE_ADDRESS }}</span
              >
              <v-text-field
                class="custom-combobox-d"
                style="width: 100%"
                outlined
                dense
                filled
                v-model="address"
                :placeholder="
                  getLanguage === 'en'
                    ? 'ex: Jl. Sudirman VII No. 01'
                    : 'contoh: Jl. Sudirman VII No. 01'
                "
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="3" class="py-0">
              <span
                class="text-base font-normal font-work-sans text-charged-grey-400"
                >{{ lang[getLanguage].TEST_DRIVE_ZIP_CODE }}</span
              >
              <v-text-field
                class="custom-combobox-d"
                style="width: 100%"
                outlined
                dense
                filled
                v-model="serviceZip"
                :disabled="serviceZip !== ''"
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6" class="py-0">
              <span
                class="text-base font-normal font-work-sans text-charged-grey-400"
                >{{ lang[getLanguage].SUBSCRIBE_HOME_OWNERSHIP_LABEL }}</span
              >
              <v-select
                class="custom-combobox-d"
                style="width: 100%"
                outlined
                dense
                filled
                v-model="homeOwnershipStatus"
                :placeholder="
                  lang[getLanguage].SUBSCRIBE_HOME_OWNERSHIP_PLACEHOLDER
                "
                :items="
                  getLanguage === 'en'
                    ? DOMICILE_INFOS.homeOwnershipStatusListEng
                    : DOMICILE_INFOS.homeOwnershipStatusListIndo
                "
                item-text="title"
                item-value="value"
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <span
                class="text-base font-normal font-work-sans text-charged-grey-400"
                >{{ lang[getLanguage].SUBSCRIBE_LENGTH_STAY_LABEL }}</span
              >
              <v-select
                class="custom-combobox-d"
                style="width: 100%"
                outlined
                dense
                filled
                v-model="lengthOfStay"
                :placeholder="
                  lang[getLanguage].SUBSCRIBE_LENGTH_STAY_PLACEHOLDER
                "
                :items="
                  getLanguage === 'en'
                    ? DOMICILE_INFOS.lengthOfStayListEnglish
                    : DOMICILE_INFOS.lengthOfStayListIndo
                "
                item-text="title"
                item-value="value"
              >
              </v-select>
            </v-col>
          </v-row>
          <!-- END RESIDENCE ADDRESS -->

          <hr
            style="border-top: 1px dashed #c1c2c4"
            class="hidden md:block mt-4"
          />

          <span
            class="md:hidden inline-block h-2 bg-charged-grey-700 w-full mb-3 mt-6"
          ></span>

          <!-- START SAME ADDRESS CHECKBOX -->
          <v-col col="12" md="12" class="text-title">
            <v-row>
              <span class="stepTitle">
                <!-- {{ lang[getLanguage].TEST_DRIVE_SHIPPING }} -->
                {{
                  getLanguage == "en"
                    ? "Address based on KTP"
                    : "Alamat berdasarkan KTP"
                }}
              </span>
            </v-row>
            <v-row>
              <v-checkbox
                class="mt-2"
                v-model="sameAddress"
                :label="
                  getLanguage == 'en'
                    ? 'Same as Residence Address'
                    : 'Sama dengan Alamat Tempat Tinggal'
                "
              ></v-checkbox>
            </v-row>
          </v-col>

          <!-- END SAME ADDRESS CHECKBOX -->

          <!-- START DIFFERENT ADDRESS CHECKBOX -->
          <div v-if="!sameAddress">
            <v-row class="mt-3">
              <v-col cols="12" md="6" class="py-0">
                <span
                  class="text-base font-normal font-work-sans text-charged-grey-400"
                  >{{ lang[getLanguage].TEST_DRIVE_PROVINCE }}</span
                >
                <v-text-field
                  class="custom-d-input custom-combobox-d"
                  style="width: 100%"
                  outlined
                  dense
                  filled
                  v-model="provinceKtp"
                  :placeholder="
                    getLanguage === 'en'
                      ? 'ex: DKI Jakarta'
                      : 'contoh: DKI Jakarta'
                  "
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <span
                  class="text-base font-normal font-work-sans text-charged-grey-400"
                  >{{ lang[getLanguage].TEST_DRIVE_CITY }}</span
                >
                <v-text-field
                  class="custom-d-input custom-combobox-d"
                  style="width: 100%"
                  outlined
                  dense
                  filled
                  v-model="cityKtp"
                  :placeholder="
                    getLanguage === 'en'
                      ? 'ex: Jakarta Selatan'
                      : 'contoh: Jakarta Selatan'
                  "
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6" class="py-0">
                <span
                  class="text-base font-normal font-work-sans text-charged-grey-400"
                  >{{ lang[getLanguage].TEST_DRIVE_DISCTRICT }}</span
                >
                <v-text-field
                  class="custom-d-input custom-combobox-d"
                  style="width: 100%"
                  outlined
                  dense
                  filled
                  v-model="districtKtp"
                  :placeholder="
                    getLanguage === 'en' ? 'ex: Cilandak' : 'contoh: Cilandak'
                  "
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <span
                  class="text-base font-normal font-work-sans text-charged-grey-400"
                  >{{ lang[getLanguage].TEST_DRIVE_SUB_DISCTRICT }}</span
                >
                <v-text-field
                  class="custom-d-input custom-combobox-d"
                  style="width: 100%"
                  outlined
                  dense
                  filled
                  v-model="subDistrictKtp"
                  :placeholder="
                    getLanguage === 'en'
                      ? 'ex: Lebak bulus'
                      : 'contoh: Lebak bulus'
                  "
                >
                </v-text-field>
              </v-col>
            </v-row>

            <!-- <v-layout style="width: 100%" pb-4> -->
            <v-row>
              <v-col cols="12" class="py-0">
                <span
                  class="text-base font-normal font-work-sans text-charged-grey-400"
                  >{{ lang[getLanguage].TEST_DRIVE_ADDRESS }}</span
                >
                <v-text-field
                  class="custom-combobox-d"
                  style="width: 100%"
                  outlined
                  dense
                  filled
                  v-model="addressKtp"
                  :placeholder="
                    getLanguage === 'en'
                      ? 'ex: Jl. Sudirman VII No. 01'
                      : 'contoh: Jl. Sudirman VII No. 01'
                  "
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="3" class="py-0">
                <span
                  class="text-base font-normal font-work-sans text-charged-grey-400"
                  >{{ lang[getLanguage].TEST_DRIVE_ZIP_CODE }}</span
                >
                <v-text-field
                  class="custom-d-input custom-combobox-d"
                  style="width: 100%"
                  outlined
                  dense
                  filled
                  v-model="postalCodeKtp"
                  :placeholder="
                    getLanguage === 'en' ? 'ex: 15416' : 'contoh: 15416'
                  "
                >
                </v-text-field>
              </v-col>
            </v-row>
          </div>
          <!-- END DIFFERENT ADDRESS CHECKBOX -->

          <hr
            style="border-top: 1px dashed #c1c2c4; margin-top: 1rem"
            class="hidden md:block"
          />

          <!-- Mobile -->
          <div class="flex justify-end mt-3 md:hidden">
            <v-btn
              class="capitalize font-work-sans font-medium text-base rounded-lg"
              color="primary"
              block
              :disabled="isValid()"
              :loading="loadingBtnStep"
              @click="handleSubmitStepThree"
            >
              Lanjutkan
              <v-icon small>mdi-chevron-right</v-icon>
            </v-btn>
          </div>
          <!-- Dekstop -->
          <div class="md:flex justify-end mt-3 hidden">
            <v-btn
              class="capitalize font-work-sans font-medium text-base rounded-lg"
              color="primary"
              right
              :disabled="isValid()"
              :loading="loadingBtnStep"
              @click="handleSubmitStepThree"
            >
              Lanjutkan
              <v-icon small>mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { formatPrice } from "../../../../store/form-subscribe/data/non-gojek/utils";
import { DOMICILE_INFOS } from "../../../../store/form-subscribe/data/non-gojek/const";
import { postResidenceInfoApi } from "../../../../services/back-end-ruby";

export default {
  name: "step-3",
  props: {
    windowWidth: Number,
    isMobileDevice: Boolean,
    goToForm: Function,
    handleSubmitStepThree: Function,
  },
  computed: {
    getLanguage() {
      return this.$store.state.user.language;
    },
    getUser() {
      return this.$store.state.user;
    },
    getServiceCity() {
      return this.$store.state.user.serviceCity;
    },
    getProfile() {
      return this.$store.getters.getProfile;
    },
    getSessionUser() {
      return this.$store.getters.getSessionUser;
    },
    getProvince() {
      return this.$store.state.province;
    },
    getCities() {
      return this.$store.state.cities;
    },
    getRegencies() {
      return this.$store.state.regencies;
    },
    getServiceCities() {
      return this.$store.state.service_cities;
    },
    getDataDomisili() {
      return this.$store.getters.getDataDomisili;
    },
  },
  data() {
    return {
      formatPrice,
      DOMICILE_INFOS,
      loading: false,
      loadingBtnStep: false,
      lang: this.$store.state.lang,
      sameAddress: false,
      provinceKtp: "",
      cityKtp: "",
      districtKtp: "",
      subDistrictKtp: "",
      addressKtp: "",
      postalCodeKtp: "",
      serviceProvince: "",
      serviceCity: "",
      serviceDistrict: "",
      serviceSubDistrict: "",
      address: "",
      serviceVillage: "",
      serviceZip: "",
      state: "",
      homeOwnershipStatus: "",
      lengthOfStay: "",
    };
  },
  watch: {
    windowWidth() {
      if (this.windowWidth < 768) {
        this.showBottomNav = false;
      } else {
        this.showBottomNav = false;
      }
    },
  },
  async mounted() {},
  async created() {
    this.$store.commit("setDataStepDone", false);
  },
  methods: {
    async getData() {
      if (this.getDataDomisili.serviceProvince == "") {
        this.serviceProvince = this.getSessionUser.serviceCity[0].province;
        this.serviceCity = this.getCities
          .filter((res) => res.province == this.serviceProvince)
          .find((c) =>
            c.name.includes(this.getSessionUser.serviceCity[0].district)
          ).name;
        this.serviceDistrict = this.getSessionUser.serviceCity[0].regency;
        this.serviceSubDistrict = this.getServiceCities
          .filter((res) => res.regency == this.serviceDistrict)
          .find((s) =>
            s.string.includes(this.getSessionUser.serviceCity[0].village)
          ).village;
        this.serviceVillage = this.getSessionUser.serviceCity[0].village;
        this.serviceZip = this.getServiceCities.find(
          (v) => v.village == this.serviceSubDistrict
        ).postcode;
      } else {
        this.serviceProvince = this.getDataDomisili.serviceProvince;
        this.serviceCity = this.getCities
          .filter((res) => res.province == this.getDataDomisili.serviceProvince)
          .find((c) => c.name.includes(this.getDataDomisili.serviceCity)).name;
        this.serviceDistrict = this.getDataDomisili.serviceDistrict;
        this.serviceSubDistrict = this.getServiceCities
          .filter((res) => res.regency == this.getDataDomisili.serviceDistrict)
          .find((s) =>
            s.string.includes(this.getDataDomisili.serviceSubDistrict)
          ).village;
        this.serviceVillage = this.getDataDomisili.serviceVillage;
        this.serviceZip = this.getServiceCities.find(
          (v) => v.village == this.serviceSubDistrict
        ).postcode;
      }

      this.address = this.getDataDomisili.address;
      this.homeOwnershipStatus = this.getDataDomisili.homeOwnershipStatus;
      this.lengthOfStay = this.getDataDomisili.lengthOfStay;
      this.sameAddress = this.getDataDomisili.sameAddress;
      if (!this.sameAddress) {
        this.provinceKtp = this.getDataDomisili.provinceKtp;
        this.cityKtp = this.getDataDomisili.cityKtp;
        this.districtKtp = this.getDataDomisili.districtKtp;
        this.subDistrictKtp = this.getDataDomisili.subDistrictKtp;
        this.addressKtp = this.getDataDomisili.addressKtp;
        this.postalCodeKtp = this.getDataDomisili.postalCodeKtp;
      }
    },
    async submit() {
      this.loadingBtnStep = true;
      let residence_infoVal = "";
      if (this.sameAddress) {
        residence_infoVal = [
          {
            address_street: this.address,
            postal_code: this.serviceZip,
            ownership_status: this.homeOwnershipStatus,
            length_of_stay: this.lengthOfStay,
            address_type: "Current Address",
          },
          {
            address_street: this.address,
            postal_code: this.serviceZip,
            ownership_status: this.homeOwnershipStatus,
            length_of_stay: this.lengthOfStay,
            address_type: "ID Address",
            province: this.serviceProvince,
            city: this.serviceCity,
            district: this.serviceDistrict,
            sub_district: this.serviceSubDistrict,
          },
        ];
      } else {
        residence_infoVal = [
          {
            address_street: this.address,
            postal_code: this.serviceZip,
            ownership_status: this.homeOwnershipStatus,
            length_of_stay: this.lengthOfStay,
            address_type: "Current Address",
          },
          {
            address_street: this.addressKtp,
            postal_code: this.postalCodeKtp,
            ownership_status: this.homeOwnershipStatus,
            length_of_stay: this.lengthOfStay,
            address_type: "ID Address",
            province: this.provinceKtp,
            city: this.cityKtp,
            district: this.districtKtp,
            sub_district: this.subDistrictKtp,
          },
        ];
      }

      const token = this.$cookies.get("token_api");
      const subsId = this.$store.getters.getIdSubs;

      await postResidenceInfoApi(token, {
        subscription_id: subsId,
        pick_up: "",
        residence_info_data: residence_infoVal,
      }).then((res) => {
        if (res.meta.code == 200) {
          this.loadingBtnStep = false;
          this.$store.commit("setDataStepDone", true);
          this.$store.commit("setDataDomisili", {
            serviceProvince: this.serviceProvince,
            serviceCity: this.serviceCity,
            serviceDistrict: this.serviceDistrict,
            serviceSubDistrict: this.serviceSubDistrict,
            address: this.address,
            serviceZip: this.serviceZip,
            homeOwnershipStatus: this.homeOwnershipStatus,
            lengthOfStay: this.lengthOfStay,
            sameAddress: this.sameAddress,
            provinceKtp: this.provinceKtp,
            cityKtp: this.cityKtp,
            districtKtp: this.districtKtp,
            subDistrictKtp: this.subDistrictKtp,
            addressKtp: this.addressKtp,
            postalCodeKtp: this.postalCodeKtp,
          });
        } else {
          this.$store.commit("setDataStepDone", false);
        }
      });
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    isAccEmpty() {
      return false;
    },
    isValid() {
      return (
        this.loadingBtnStep ||
        this.serviceProvince === "" ||
        this.serviceCity === "" ||
        this.serviceDistrict === "" ||
        this.serviceSubDistrict === "" ||
        this.address === "" ||
        this.serviceZip === "" ||
        this.homeOwnershipStatus === "" ||
        this.lengthOfStay === "" ||
        (this.provinceKtp === "" && !this.sameAddress) ||
        (this.cityKtp === "" && !this.sameAddress) ||
        (this.districtKtp === "" && !this.sameAddress) ||
        (this.subDistrictKtp === "" && !this.sameAddress) ||
        (this.addressKtp === "" && !this.sameAddress) ||
        (this.postalCodeKtp === "" && !this.sameAddress)
      );
    },
    changeProvince() {
      this.retsetKKKP();
    },
    changeCity() {
      this.resetKKP();
    },
    changeSubDistrict() {
      this.getServiceCities.filter((v) => {
        if (v.village == this.serviceSubDistrict) {
          this.serviceZip = v.postcode;
        }
      });
    },
    changeSubDistrictKtp() {
      this.getServiceCities.filter((v) => {
        if (v.village == this.subDistrictKtp) {
          this.postalCodeKtp = v.postcode;
        }
      });
    },
    retsetKKKP() {
      this.serviceCity = "";
      this.serviceDistrict = "";
      this.serviceSubDistrict = "";
      this.serviceZip = "";
    },
    resetKKP() {
      this.serviceDistrict = "";
      this.serviceSubDistrict = "";
      this.serviceZip = "";
    },
  },
};
</script>

<style></style>