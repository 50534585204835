<template>
  <div class="pb-10">
    <v-row>
      <v-col cols="12" md="8">
        <v-col class="stepBack py-0">
          <v-btn
            class="pl-0 md:pl-4"
            @click.stop="backStep"
            text
            color="primary"
            :ripple="false"
            ><v-icon color="primary" class="mr-1">mdi-chevron-left</v-icon
            >{{ lang[getLanguage].BUT_BACK }}
          </v-btn>
        </v-col>
        <v-card flat mb-4>
          <v-col col="12" md="12" class="text-title">
            <v-row>
              <span id="stepTitle" class="stepTitle">{{
                lang[getLanguage].TEST_DRIVE_ADDRESS_TITLE
              }}</span>
            </v-row>
          </v-col>

          <v-row class="mt-3">
            <v-col cols="12" md="6" class="py-0">
              <span
                class="text-base font-normal font-work-sans text-charged-grey-400"
                >{{ lang[getLanguage].TEST_DRIVE_PROVINCE }}</span
              >
              <v-select
                class="custom-combobox-d"
                style="width: 100%"
                outlined
                dense
                filled
                v-model="serviceProvince"
                :items="getProvince"
                item-text="name"
                @change="changeProvince"
                :disabled="serviceProvince !== ''"
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <span
                class="text-base font-normal font-work-sans text-charged-grey-400"
                >{{ lang[getLanguage].TEST_DRIVE_CITY }}</span
              >
              <!-- <v-text-field
                class="custom-d-input custom-combobox-d"
                style="width: 100%"
                outlined
                dense
                filled
                v-model="serviceCity"
              >
              </v-text-field> -->
              <v-select
                class="custom-combobox-d"
                style="width: 100%"
                outlined
                dense
                filled
                v-model="serviceCity"
                :items="
                  getCities.filter((res) => res.province == serviceProvince)
                "
                item-text="name"
                :disabled="serviceCity !== ''"
                @change="changeCity"
              >
              </v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6" class="py-0">
              <span
                class="text-base font-normal font-work-sans text-charged-grey-400"
                >{{ lang[getLanguage].TEST_DRIVE_DISCTRICT }}</span
              >
              <!-- <v-text-field
                class="custom-d-input custom-combobox-d"
                style="width: 100%"
                outlined
                dense
                filled
                v-model="serviceDistrict"
              >
              </v-text-field> -->
              <v-select
                class="custom-combobox-d"
                style="width: 100%"
                outlined
                dense
                filled
                v-model="serviceDistrict"
                :items="getRegencies.filter((res) => res.city == serviceCity)"
                :disabled="serviceDistrict !== ''"
                item-text="name"
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <span
                class="text-base font-normal font-work-sans text-charged-grey-400"
                >{{ lang[getLanguage].TEST_DRIVE_SUB_DISCTRICT }}</span
              >
              <!-- <v-text-field
                class="custom-d-input custom-combobox-d"
                style="width: 100%"
                outlined
                dense
                filled
                v-model="serviceSubDistrict"
              >
              </v-text-field> -->
              <v-select
                class="custom-combobox-d"
                style="width: 100%"
                outlined
                dense
                filled
                v-model="serviceSubDistrict"
                :items="
                  getServiceCities.filter(
                    (res) =>
                      res.regency == serviceDistrict &&
                      serviceCity == `${res.state + ' ' + res.district}`
                  )
                "
                item-id="id"
                item-text="village"
                :disabled="serviceSubDistrict !== ''"
                @change="changeSubDistrict"
              >
              </v-select>
            </v-col>
          </v-row>

          <!-- <v-layout style="width: 100%" pb-4> -->
          <v-row>
            <v-col cols="12" class="py-0">
              <span
                class="text-base font-normal font-work-sans text-charged-grey-400"
                >{{ lang[getLanguage].TEST_DRIVE_ADDRESS }}</span
              >
              <v-text-field
                class="custom-combobox-d"
                style="width: 100%"
                outlined
                dense
                filled
                v-model="address"
                :placeholder="
                  getLanguage === 'en'
                    ? 'ex: Jl. Sudirman VII No. 01'
                    : 'contoh: Jl. Sudirman VII No. 01'
                "
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="3" class="py-0">
              <span
                class="text-base font-normal font-work-sans text-charged-grey-400"
                >{{ lang[getLanguage].TEST_DRIVE_ZIP_CODE }}</span
              >
              <v-text-field
                class="custom-combobox-d"
                style="width: 100%"
                outlined
                dense
                filled
                v-model="serviceZip"
                :disabled="serviceZip !== ''"
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6" class="py-0">
              <span
                class="text-base font-normal font-work-sans text-charged-grey-400"
                >{{ lang[getLanguage].SUBSCRIBE_HOME_OWNERSHIP_LABEL }}</span
              >
              <v-select
                class="custom-combobox-d"
                style="width: 100%"
                outlined
                dense
                filled
                v-model="homeOwnershipStatus"
                :placeholder="
                  lang[getLanguage].SUBSCRIBE_HOME_OWNERSHIP_PLACEHOLDER
                "
                :items="
                  getLanguage === 'en'
                    ? homeOwnershipStatusListEng
                    : homeOwnershipStatusListIndo
                "
                item-text="title"
                item-value="value"
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <span
                class="text-base font-normal font-work-sans text-charged-grey-400"
                >{{ lang[getLanguage].SUBSCRIBE_LENGTH_STAY_LABEL }}</span
              >
              <v-select
                class="custom-combobox-d"
                style="width: 100%"
                outlined
                dense
                filled
                v-model="lengthOfStay"
                :placeholder="
                  lang[getLanguage].SUBSCRIBE_LENGTH_STAY_PLACEHOLDER
                "
                :items="
                  getLanguage === 'en'
                    ? lengthOfStayListEnglish
                    : lengthOfStayListIndo
                "
                item-text="title"
                item-value="value"
              >
              </v-select>
            </v-col>
          </v-row>

          <hr
            style="border-top: 1px dashed #c1c2c4"
            class="hidden md:block mt-4"
          />

          <span
            class="md:hidden inline-block h-2 bg-charged-grey-700 w-full mb-2 mt-4"
          ></span>

          <v-col col="12" md="12" class="text-title">
            <v-row>
              <span class="stepTitle">
                <!-- {{ lang[getLanguage].TEST_DRIVE_SHIPPING }} -->
                {{
                  getLanguage == "en"
                    ? "Address based on KTP"
                    : "Alamat berdasarkan KTP"
                }}
              </span>
            </v-row>
          </v-col>

          <v-checkbox
            v-model="sameAddress"
            :label="
              getLanguage == 'en'
                ? 'Same as Residence Address'
                : 'Sama dengan Alamat Tempat Tinggal'
            "
          ></v-checkbox>
          <div v-if="!sameAddress">
            <v-row class="mt-3">
              <v-col cols="12" md="6" class="py-0">
                <span
                  class="text-base font-normal font-work-sans text-charged-grey-400"
                  >{{ lang[getLanguage].TEST_DRIVE_PROVINCE }}</span
                >
                <!-- <v-select
                  class="custom-d-input custom-combobox-d"
                  style="width: 100%"
                  outlined
                  dense
                  filled
                  v-model="provinceKtp"
                  :items="getProvince"
                  item-text="name"
                  :placeholder="
                    getLanguage === 'en'
                      ? 'ex: DKI Jakarta'
                      : 'contoh: DKI Jakarta'
                  "
                >
                </v-select> -->
                <v-text-field
                  class="custom-d-input custom-combobox-d"
                  style="width: 100%"
                  outlined
                  dense
                  filled
                  v-model="provinceKtp"
                  :placeholder="
                    getLanguage === 'en'
                      ? 'ex: DKI Jakarta'
                      : 'contoh: DKI Jakarta'
                  "
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <span
                  class="text-base font-normal font-work-sans text-charged-grey-400"
                  >{{ lang[getLanguage].TEST_DRIVE_CITY }}</span
                >
                <!-- <v-select
                  class="custom-d-input custom-combobox-d"
                  style="width: 100%"
                  outlined
                  dense
                  filled
                  v-model="cityKtp"
                  :items="
                    getCities.filter((res) => res.province == provinceKtp)
                  "
                  item-text="name"
                  :placeholder="
                    getLanguage === 'en'
                      ? 'ex: Jakarta Selatan'
                      : 'contoh: Jakarta Selatan'
                  "
                >
                </v-select> -->
                <v-text-field
                  class="custom-d-input custom-combobox-d"
                  style="width: 100%"
                  outlined
                  dense
                  filled
                  v-model="cityKtp"
                  :placeholder="
                    getLanguage === 'en'
                      ? 'ex: Jakarta Selatan'
                      : 'contoh: Jakarta Selatan'
                  "
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6" class="py-0">
                <span
                  class="text-base font-normal font-work-sans text-charged-grey-400"
                  >{{ lang[getLanguage].TEST_DRIVE_DISCTRICT }}</span
                >
                <!-- <v-select
                  class="custom-d-input custom-combobox-d"
                  style="width: 100%"
                  outlined
                  dense
                  filled
                  v-model="districtKtp"
                  :items="getRegencies.filter((res) => res.city == cityKtp)"
                  item-text="name"
                  :placeholder="
                    getLanguage === 'en' ? 'ex: Cilandak' : 'contoh: Cilandak'
                  "
                >
                </v-select> -->
                <v-text-field
                  class="custom-d-input custom-combobox-d"
                  style="width: 100%"
                  outlined
                  dense
                  filled
                  v-model="districtKtp"
                  :placeholder="
                    getLanguage === 'en' ? 'ex: Cilandak' : 'contoh: Cilandak'
                  "
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <span
                  class="text-base font-normal font-work-sans text-charged-grey-400"
                  >{{ lang[getLanguage].TEST_DRIVE_SUB_DISCTRICT }}</span
                >
                <!-- <v-select
                  class="custom-d-input custom-combobox-d"
                  style="width: 100%"
                  outlined
                  dense
                  filled
                  v-model="subDistrictKtp"
                  :items="
                    getServiceCities.filter((res) => res.regency == districtKtp)
                  "
                  item-id="id"
                  item-text="village"
                  @change="changeSubDistrictKtp"
                  :placeholder="
                    getLanguage === 'en'
                      ? 'ex: Lebak bulus'
                      : 'contoh: Lebak bulus'
                  "
                >
                </v-select> -->
                <v-text-field
                  class="custom-d-input custom-combobox-d"
                  style="width: 100%"
                  outlined
                  dense
                  filled
                  v-model="subDistrictKtp"
                  :placeholder="
                    getLanguage === 'en'
                      ? 'ex: Lebak bulus'
                      : 'contoh: Lebak bulus'
                  "
                >
                </v-text-field>
              </v-col>
            </v-row>

            <!-- <v-layout style="width: 100%" pb-4> -->
            <v-row>
              <v-col cols="12" class="py-0">
                <span
                  class="text-base font-normal font-work-sans text-charged-grey-400"
                  >{{ lang[getLanguage].TEST_DRIVE_ADDRESS }}</span
                >
                <v-text-field
                  class="custom-combobox-d"
                  style="width: 100%"
                  outlined
                  dense
                  filled
                  v-model="addressKtp"
                  :placeholder="
                    getLanguage === 'en'
                      ? 'ex: Jl. Sudirman VII No. 01'
                      : 'contoh: Jl. Sudirman VII No. 01'
                  "
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="3" class="py-0">
                <span
                  class="text-base font-normal font-work-sans text-charged-grey-400"
                  >{{ lang[getLanguage].TEST_DRIVE_ZIP_CODE }}</span
                >
                <v-text-field
                  class="custom-d-input custom-combobox-d"
                  style="width: 100%"
                  outlined
                  dense
                  filled
                  v-model="postalCodeKtp"
                  :placeholder="
                    getLanguage === 'en' ? 'ex: 15416' : 'contoh: 15416'
                  "
                >
                </v-text-field>
              </v-col>
            </v-row>
          </div>

          <span
            class="md:hidden inline-block h-2 bg-charged-grey-700 w-full mb-2 mt-4"
          ></span>

          <span
            class="w-full border border-dashed md:inline-block hidden"
          ></span>
          <!-- Mobile -->
          <div class="flex justify-end mt-3 md:hidden">
            <v-btn
              class="capitalize font-work-sans font-medium text-base rounded-lg"
              color="primary"
              block
              :disabled="isValid()"
              :loading="loadingBtnStep"
              @click="stepThreeComplete"
            >
              {{ lang[getLanguage].BUT_COMMON_CONTINUE }}
              <v-icon small>mdi-chevron-right</v-icon>
            </v-btn>
          </div>
          <!-- Dekstop -->
          <div class="md:flex justify-end mt-3 hidden">
            <v-btn
              class="capitalize font-work-sans font-medium text-base rounded-lg"
              color="primary"
              right
              :disabled="isValid()"
              :loading="loadingBtnStep"
              @click="stepThreeComplete"
            >
              {{ lang[getLanguage].BUT_COMMON_CONTINUE }}
              <v-icon small>mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import store from "@/store/index";
import {
  getPrivateDocApi,
  postResidenceInfoApi,
} from "../../../../services/back-end-ruby";
// import { postResidenceInfoApi } from "../../../services/back-end-ruby";
export default {
  name: "form-subs-step-3",
  metaInfo() {
    return {
      title: this.metaTitle,
      meta: [
        { name: "description", content: this.metaDesc },
        { property: "og:title", content: this.metaTitle },
        { property: "og:og:image", content: this.metaImg },
        { property: "og:og:url", content: this.metaUrl },
        { property: "og:site_name", content: "Charged Asia" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
  props: {
    dark: Boolean,
    windowWidth: Number,
    isMobileDevice: Boolean,
    windowHeight: Number,
    index: Number,
    profile: Object,
    backStep: Function,
    stepThreeComplete: Function,
  },
  data() {
    return {
      dialogDeposit: false,
      loadingBtnStep: false,
      provinceKtp: "",
      cityKtp: "",
      districtKtp: "",
      subDistrictKtp: "",
      addressKtp: "",
      postalCodeKtp: "",
      sameAddress: false,
      serviceProvince: "",
      serviceCity: "",
      serviceDistrict: "",
      serviceSubDistrict: "",
      address: "",
      serviceVillage: "",
      serviceZip: "",
      state: "",
      lang: this.$store.state.lang,
      homeOwnershipStatus: "",
      homeOwnershipStatusListEng: [
        {
          title: "Monthly Rent",
          value: "monthly_rental",
        },
        {
          title: "Annual Rentt",
          value: "annual_rental",
        },
        {
          title: "Owned",
          value: "owned",
        },
      ],
      homeOwnershipStatusListIndo: [
        {
          title: "Kontrak Bulanan",
          value: "monthly_rental",
        },
        {
          title: "Kontrak Tahunan",
          value: "annual_rental",
        },
        {
          title: "Milik",
          value: "owned",
        },
      ],
      lengthOfStay: "",
      lengthOfStayListEnglish: [
        {
          title: "< 1 years",
          value: "less_than_one_year",
        },
        {
          title: "1-3 years",
          value: "one_to_three_years",
        },
        {
          title: "4-6 years",
          value: "four_to_six_years",
        },
        {
          title: "> 6 years",
          value: "more_than_six_years",
        },
      ],
      lengthOfStayListIndo: [
        {
          title: "< 1 tahun",
          value: "less_than_one_year",
        },
        {
          title: "1-3 tahun",
          value: "one_to_three_years",
        },
        {
          title: "4-6 tahun",
          value: "four_to_six_years",
        },
        {
          title: "> 6 tahun",
          value: "more_than_six_years",
        },
      ],
    };
  },
  components: {},
  watch: {
    windowWidth() {
      if (this.windowWidth < 768) {
        this.showBottomNav = false;
      } else {
        this.showBottomNav = false;
      }
    },
  },
  computed: {
    getLanguage() {
      return this.$store.state.user.language;
    },
    getUser() {
      return this.$store.state.user;
    },
    getServiceCity() {
      return this.$store.state.user.serviceCity;
    },
    getProfile() {
      return this.$store.getters.getProfile;
    },
    getSessionUser() {
      return this.$store.getters.getSessionUser;
    },
    getProvince() {
      return this.$store.state.province;
    },
    getCities() {
      return this.$store.state.cities;
    },
    getRegencies() {
      return this.$store.state.regencies;
    },
    getServiceCities() {
      return this.$store.state.service_cities;
    },
    getDataGojekDomisili() {
      return this.$store.getters.getDataGojekDomisili;
    },
  },
  mounted() {},
  created() {
    this.$store.commit("setDataGojekStepDone", false);
  },
  methods: {
    async getData() {
      // console.log("GET_CITY", this.getCities);
      // console.log(
      //   "PROVINCE",
      //   this.getProvince.find((e) =>
      //     e.name.includes(this.getDataGojekDomisili.serviceProvince)
      //   )
      // );

      // if (
      //   this.getProvince.find((e) =>
      //     e.name.includes(this.getDataGojekDomisili.serviceProvince)
      //   ) == undefined
      // ) {
      //   this.serviceProvince = "";
      // }
      // this.getDataGojekDomisili.serviceProvince =
      //   this.getProvince.find(
      //     (e) => e.name == this.getDataGojekDomisili.serviceProvince
      //   ) ?? "";

      if (this.getDataGojekDomisili.serviceProvince == "") {
        this.serviceProvince = this.getSessionUser.serviceCity[0].province;
        this.serviceCity =
          this.getCities
            .filter((res) => res.province == this.serviceProvince)
            .find((c) =>
              c.name.includes(this.getSessionUser.serviceCity[0].district)
            )?.name ?? "";
        this.serviceDistrict = this.getSessionUser.serviceCity[0].regency;
        this.serviceSubDistrict = this.getServiceCities
          .filter((res) => res.regency == this.serviceDistrict)
          .find((s) =>
            s.string.includes(this.getSessionUser.serviceCity[0].village)
          ).village;
        this.serviceVillage = this.getSessionUser.serviceCity[0].village;
        this.serviceZip = this.getServiceCities.find(
          (v) => v.village == this.serviceSubDistrict
        ).postcode;
      } else {
        this.serviceProvince =
          this.getProvince.find((e) =>
            e.name.includes(this.getDataGojekDomisili.serviceProvince)
          )?.name ?? "";
        // this.serviceCity = this.getDataGojekDomisili.serviceCity;
        this.serviceCity =
          this.getCities
            .filter(
              (res) => res.province == this.getDataGojekDomisili.serviceProvince
            )
            .find((c) => c.name.includes(this.getDataGojekDomisili.serviceCity))
            ?.name ?? "";
        this.serviceDistrict = this.getDataGojekDomisili.serviceDistrict;
        // this.serviceSubDistrict = this.getDataGojekDomisili.serviceSubDistrict;
        this.serviceSubDistrict = this.getServiceCities
          .filter(
            (res) => res.regency == this.getDataGojekDomisili.serviceDistrict
          )
          .find((s) =>
            s.string.includes(this.getDataGojekDomisili.serviceSubDistrict)
          ).village;
        this.serviceVillage = this.getDataGojekDomisili.serviceVillage;
        // this.serviceZip = this.getDataGojekDomisili.serviceZip;
        this.serviceZip = this.getServiceCities.find(
          (v) => v.village == this.serviceSubDistrict
        ).postcode;
      }

      this.address = this.getDataGojekDomisili.address;
      this.homeOwnershipStatus = this.getDataGojekDomisili.homeOwnershipStatus;
      this.lengthOfStay = this.getDataGojekDomisili.lengthOfStay;
      this.sameAddress = this.getDataGojekDomisili.sameAddress;
      if (!this.sameAddress) {
        this.provinceKtp = this.getDataGojekDomisili.provinceKtp;
        this.cityKtp = this.getDataGojekDomisili.cityKtp;
        this.districtKtp = this.getDataGojekDomisili.districtKtp;
        this.subDistrictKtp = this.getDataGojekDomisili.subDistrictKtp;
        this.addressKtp = this.getDataGojekDomisili.addressKtp;
        this.postalCodeKtp = this.getDataGojekDomisili.postalCodeKtp;
      }
    },
    async submit() {
      this.loadingBtnStep = true;
      let residence_infoVal = "";
      if (this.sameAddress) {
        residence_infoVal = [
          {
            address_street: this.address,
            postal_code: this.serviceZip,
            ownership_status: this.homeOwnershipStatus,
            length_of_stay: this.lengthOfStay,
            address_type: "Current Address",
          },
          {
            address_street: this.address,
            postal_code: this.serviceZip,
            ownership_status: this.homeOwnershipStatus,
            length_of_stay: this.lengthOfStay,
            address_type: "ID Address",
            province: this.serviceProvince,
            city: this.serviceCity,
            district: this.serviceDistrict,
            sub_district: this.serviceSubDistrict,
          },
        ];
      } else {
        residence_infoVal = [
          {
            address_street: this.address,
            postal_code: this.serviceZip,
            ownership_status: this.homeOwnershipStatus,
            length_of_stay: this.lengthOfStay,
            address_type: "Current Address",
          },
          {
            address_street: this.addressKtp,
            postal_code: this.postalCodeKtp,
            ownership_status: this.homeOwnershipStatus,
            length_of_stay: this.lengthOfStay,
            address_type: "ID Address",
            province: this.provinceKtp,
            city: this.cityKtp,
            district: this.districtKtp,
            sub_district: this.subDistrictKtp,
          },
        ];
      }
      await postResidenceInfoApi(this.$cookies.get("token_api"), {
        subscription_id: this.$store.getters.getIdSubs,
        pick_up: "",
        residence_info_data: residence_infoVal,
      }).then((res) => {
        if (res.meta.code == 200) {
          this.stepper = 4;
          // this.$router.push("/form-subscribe/4");
          // this.sendResidenceInfo = true;
          // this.selectedPricing = this.listPricing[0];
          this.loadingBtnStep = false;
          this.$store.commit("setDataGojekStepDone", true);
          this.$store.commit("setDataGojekDomisili", {
            serviceProvince: this.serviceProvince,
            serviceCity: this.serviceCity,
            serviceDistrict: this.serviceDistrict,
            serviceSubDistrict: this.serviceSubDistrict,
            address: this.address,
            serviceZip: this.serviceZip,
            homeOwnershipStatus: this.homeOwnershipStatus,
            lengthOfStay: this.lengthOfStay,
            sameAddress: this.sameAddress,
            provinceKtp: this.provinceKtp,
            cityKtp: this.cityKtp,
            districtKtp: this.districtKtp,
            subDistrictKtp: this.subDistrictKtp,
            addressKtp: this.addressKtp,
            postalCodeKtp: this.postalCodeKtp,
          });
          getPrivateDocApi(this.$cookies.get("token_api")).then((ress) => {
            console.log("GET_FILE_DOC", ress);
            // const fileName = ress.data.user_documents[0].document_type;
            // this.file.identityCard.file = new File([ress.data.user_documents[0].file.url], fileName, { type: 'image/png' });
          });
        } else {
          this.$store.commit("setDataGojekStepDone", false);
        }
      });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    routerGo(route) {
      window.open(route, "_blank");
    },
    changeLanguage(lang) {
      store.commit("SetLanguage", {
        language: (this.getLanguage = lang),
      });
    },
    activateReadMore() {
      this.subscriptionPolicyFull.readMoreActive = true;
    },
    deactivateReadMore() {
      this.subscriptionPolicyFull.readMoreActive = false;
    },
    changeProvince() {
      this.retsetKKKP();
      // console.log(this.getServiceCities);
    },
    changeCity() {
      this.resetKKP();
    },
    changeSubDistrict() {
      this.getServiceCities.filter((v) => {
        if (v.village == this.serviceSubDistrict) {
          this.serviceZip = v.postcode;
        }
      });
    },
    changeSubDistrictKtp() {
      this.getServiceCities.filter((v) => {
        if (v.village == this.subDistrictKtp) {
          this.postalCodeKtp = v.postcode;
        }
      });
    },
    retsetKKKP() {
      this.serviceCity = "";
      this.serviceDistrict = "";
      this.serviceSubDistrict = "";
      this.serviceZip = "";
    },
    resetKKP() {
      this.serviceDistrict = "";
      this.serviceSubDistrict = "";
      this.serviceZip = "";
    },

    isValid() {
      return (
        this.loadingBtnStep ||
        this.serviceProvince === "" ||
        this.serviceCity === "" ||
        this.serviceDistrict === "" ||
        this.serviceSubDistrict === "" ||
        this.address === "" ||
        this.serviceZip === "" ||
        this.homeOwnershipStatus === "" ||
        this.lengthOfStay === "" ||
        (this.provinceKtp === "" && !this.sameAddress) ||
        (this.cityKtp === "" && !this.sameAddress) ||
        (this.districtKtp === "" && !this.sameAddress) ||
        (this.subDistrictKtp === "" && !this.sameAddress) ||
        (this.addressKtp === "" && !this.sameAddress) ||
        (this.postalCodeKtp === "" && !this.sameAddress)
      );
    },
  },
};
</script>