<template>
  <!-- <div v-if="selectedBike === null"> -->
  <div>
    <v-dialog v-model="loadingScreen" width="auto" persistent>
      <v-card>
        <v-card-actions class="loading-screen">
          <v-progress-circular color="primary" indeterminate>
          </v-progress-circular>
          <v-card-text class="subheading text-center">
            <b>{{ lang[getLanguage].WAIT_TITLE }}</b>
          </v-card-text>
          <v-card-text class="text-center">
            {{ lang[getLanguage].WAIT_DESCRIPTION }}
          </v-card-text>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="alertDialog" persistent max-width="500">
      <v-card class="alert-dialog">
        <v-icon v-if="this.alertStatus == 'success'" class="succes-icon"
          >mdi-check-circle</v-icon
        >
        <v-icon v-if="this.alertStatus == 'failed'" class="failed-icon"
          >mdi-close-circle</v-icon
        >
        <v-card-text class="title text-center">{{
          getLanguage === "en" ? this.alertText.en : this.alertText.id
        }}</v-card-text>
        <!-- <v-card-text class="text-center">
          {{ lang[getLanguage].BOOKING_FAILED_DESCRIPTION }}
        </v-card-text> -->
        <v-btn
          color="primary"
          :width="windowWidth < 768 ? '100%' : '50%'"
          @click="closeAlertDialog"
          class="color:#2962FF;margin-left:-10px;margin-top:-4px;font-weight:normal;font-size:11px;text-transform: none !important;"
          >Ok</v-btn
        >
      </v-card>
    </v-dialog>

    <v-row>
      <v-col cols="12" md="8">
        <v-col class="stepBack pt-0">
          <v-btn
            class="pl-0 md:pl-4"
            @click.stop="goToForm(5)"
            text
            color="primary"
            :ripple="false"
            style="font-weight: 600"
          >
            <v-icon color="primary" class="mr-1">mdi-chevron-left</v-icon
            >{{ lang[getLanguage].BUT_BACK }}
          </v-btn>
        </v-col>

        <span class="stepTitle">{{ lang[getLanguage].PRODUCT_STEP_6 }}</span>

        <v-layout>
          <v-col col="12" md="12">
            <v-row>
              <v-col v-if="selectedBikeInfos?.bike !== null" col="12" md="5">
                <v-container fluid pa-0>
                  <v-img
                    :src="selectedBikeInfos?.bike.image"
                    max-width="250"
                  ></v-img>
                </v-container>
              </v-col>

              <v-col col="12" md="7">
                <v-row class="your-order">
                  <p>
                    <b>{{ selectedBikeInfos?.bike?.name }}</b>
                  </p>
                  <v-layout
                    v-if="selectedBikeInfos?.color != null"
                    class="mb-3"
                  >
                    <v-icon
                      class="radio-selection-color"
                      :color="selectedBikeInfos?.color.color"
                      >mdi-circle
                    </v-icon>
                    -
                    {{
                      getLanguage === "en"
                        ? selectedBikeInfos?.color.title.en
                        : selectedBikeInfos?.color.title.id
                    }}
                  </v-layout>

                  <v-layout style="width: 100%">
                    <v-layout>
                      <span
                        class="review-order-item-label"
                        style="min-width: 150px"
                        >{{ lang[getLanguage].LABEL_CHARGER }}</span
                      >
                      :
                      <span
                        v-if="selectedBikeInfos?.typeCharge != null"
                        class="review-order-item-label"
                        style="margin-left: 20px"
                        >{{ selectedBikeInfos?.typeCharge.title }}</span
                      >
                    </v-layout>
                  </v-layout>
                  <v-layout style="width: 100%">
                    <v-layout>
                      <span
                        class="review-order-item-label"
                        style="min-width: 150px"
                        >{{ lang[getLanguage].LABEL_BATTERY }}</span
                      >
                      <span class="review-order-item-label">:</span>
                      <span
                        v-if="selectedBikeInfos?.qtyBattery != null"
                        class="review-order-item-label"
                        style="margin-left: 20px"
                      >
                        {{
                          getLanguage === "en"
                            ? selectedBikeInfos?.qtyBattery.qty
                            : selectedBikeInfos?.qtyBattery.qty
                        }}
                        <span
                          v-if="
                            getLanguage == 'en' &&
                            selectedBikeInfos?.qtyBattery.qty > 1
                          "
                          class="text-sm text-charged-grey-400"
                          >(Additional battery included)</span
                        >
                        <span
                          v-if="
                            getLanguage == 'id' &&
                            selectedBikeInfos?.qtyBattery.qty > 1
                          "
                          class="text-sm text-charged-grey-400"
                          >(Termasuk tambahan baterai)</span
                        >
                      </span>
                    </v-layout>
                  </v-layout>

                  <v-layout
                    style="width: 100%"
                    v-if="selectedBikeInfos?.accessories != null"
                  >
                    <v-layout class="mt-1">
                      <span
                        class="review-order-item-label"
                        style="min-width: 150px"
                        >Tambahan Aksesoris</span
                      >
                      <span class="review-order-item-label">:</span>
                      <span
                        v-if="selectedBikeInfos?.accessories != null"
                        class="review-order-item-label"
                        style="margin-left: 20px"
                        >{{
                          getLanguage === "en"
                            ? selectedBikeInfos?.accessories.conclusion.en
                            : selectedBikeInfos?.accessories.conclusion.id
                        }}
                        <span class="text-sm text-charged-grey-400">
                          ({{ selectedBikeInfos?.accessories.description }})
                        </span>
                      </span>
                    </v-layout>
                  </v-layout>
                  <p v-if="selectedPricing !== null" class="mt-5 font-bold">
                    {{ lang[getLanguage].SUBCRIPTION_TIME_LABEL }}
                    <b>
                      {{
                        getLanguage === "en"
                          ? selectedPricing.plan.en
                          : selectedPricing.plan.id
                      }}</b
                    >
                  </p>

                  <v-btn
                    @click="goToForm(1)"
                    text
                    color="primary"
                    :ripple="false"
                    class="text-capitalize no-background-hover px-3"
                  >
                    <v-row>
                      <p class="edit-button">
                        {{ getLanguage == "en" ? "Edit" : "Ubah" }}
                      </p>
                    </v-row>
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-layout>

        <hr
          style="border-top: 1px dashed #c1c2c4; margin: 1rem 0"
          class="hidden md:block"
        />
        <span
          class="md:hidden inline-block h-2 bg-charged-grey-700 w-full mb-2 mt-4"
        ></span>

        <v-layout>
          <div>
            <v-col class="text-title">
              <v-row>
                <span class="stepTitleSmall">{{
                  lang[getLanguage].REVIEW_PRODUCT_STEP_2
                }}</span>
              </v-row>
            </v-col>
            <div v-if="isMobileDevice">
              <v-row class="mt-3">
                <v-col col="12" md="12" class="customer-information">
                  <span class="customer-information-title mb-2">{{
                    lang[getLanguage].ACC_PERSONAL
                  }}</span>
                  <span class="customer-information-name">{{
                    personalInfos?.name
                  }}</span>
                  <span class="customer-information-phone">{{
                    personalInfos?.phoneNumberPribadi
                  }}</span>
                  <span class="customer-information-email">{{
                    personalInfos?.emailPribadi
                  }}</span>
                </v-col>
              </v-row>
              <v-row class="mb-6">
                <v-col col="12" md="12" class="customer-information">
                  <span class="customer-information-title mb-2">{{
                    lang[getLanguage].LABEL_RESIDENCE
                  }}</span>
                  <span class="customer-information-email"
                    >{{ domisiliInfos?.address }},</span
                  >
                  <span class="customer-information-email"
                    >{{ domisiliInfos?.serviceDistrict }},
                    {{ domisiliInfos?.serviceSubDistrict }},
                    {{ domisiliInfos?.serviceCity }},</span
                  >
                  <span class="customer-information-email"
                    >{{ domisiliInfos?.serviceProvince }}
                    {{ domisiliInfos?.serviceZip }}</span
                  >
                </v-col>
              </v-row>
            </div>
            <div v-else>
              <v-row class="mt-3 mb-6">
                <v-col
                  cols="12"
                  md="4"
                  class="customer-information"
                  style="width: 600px"
                >
                  <span class="customer-information-title mb-2">{{
                    lang[getLanguage].ACC_PERSONAL
                  }}</span>
                  <span class="customer-information-name">{{
                    personalInfos?.name
                  }}</span>
                  <span class="customer-information-phone">{{
                    personalInfos?.nik
                  }}</span>
                  <span class="customer-information-phone">{{
                    personalInfos?.phoneNumberPribadi
                  }}</span>
                  <span class="customer-information-email">{{
                    personalInfos?.emailPribadi
                  }}</span>
                </v-col>
                <v-col cols="12" md="4" class="customer-information">
                  <span class="customer-information-title mb-2">{{
                    lang[getLanguage].LABEL_RESIDENCE
                  }}</span>
                  <span class="customer-information-email"
                    >{{ domisiliInfos?.address }},</span
                  >
                  <span class="customer-information-email"
                    >{{ domisiliInfos?.serviceDistrict }},
                    {{ domisiliInfos?.serviceSubDistrict }},
                    {{ domisiliInfos?.serviceCity }},</span
                  >
                  <span class="customer-information-email"
                    >{{ domisiliInfos?.serviceProvince }}
                    {{ domisiliInfos?.serviceZip }}</span
                  >
                </v-col>
              </v-row>
            </div>

            <v-btn
              @click="goToForm(3)"
              text
              color="primary"
              :ripple="false"
              class="text-capitalize no-background-hover px-3"
            >
              <v-row class="">
                <p class="edit-button">
                  {{ getLanguage == "en" ? "Edit" : "Ubah" }}
                </p>
              </v-row>
            </v-btn>
          </div>
        </v-layout>

        <hr
          style="border-top: 1px dashed #c1c2c4; margin: 1rem 0"
          class="hidden md:block"
        />
        <span
          class="md:hidden inline-block h-2 bg-charged-grey-700 w-full mb-2 mt-4"
        ></span>

        <v-card flat mb-4>
          <span class="stepTitle">{{
            lang[getLanguage].PRODUCT_SUBSCRIBE_POLICY_TITLE
          }}</span>
          <p class="mt-2"></p>

          <span
            class="subInfo text-sm md:text-base"
            style="transition: height 2s"
            v-if="subscriptionPolicyFull.readMoreActive"
            >{{
              getLanguage == "en"
                ? subscriptionPolicyFull.en
                : subscriptionPolicyFull.id
            }}</span
          >
          <span class="subInfo" v-if="!subscriptionPolicyFull.readMoreActive"
            >..</span
          >

          <br />
          <p class="mt-2"></p>
          <v-checkbox
            v-model="subscriptionPolicy"
            required
            :rules="[(v) => !!v]"
          >
            <template v-slot:label>
              <div
                :style="windowWidth < 325 ? 'font-size:11px' : 'font-size:14px'"
              >
                {{ lang[getLanguage].PRODUCT_ACCEPT }}
                <span
                  style="
                    cursor: pointer;
                    text-decoration: underline;
                    color: #6b4693;
                    font-weight: 500;
                  "
                  @click="openPolicy"
                  >{{ lang[getLanguage].SALES_AGREEMENT }}</span
                >
                {{ lang[getLanguage].LABEL_AND }}
                <span
                  style="
                    cursor: pointer;
                    text-decoration: underline;
                    color: #6b4693;
                    font-weight: 500;
                  "
                  @click="routerGo('/term-of-use')"
                  >Ketentuan Layanan.</span
                >
              </div>
            </template>
          </v-checkbox>

          <hr style="border-top: 1px dashed #c1c2c4" class="hidden md:block" />
          <span
            class="md:hidden inline-block h-2 bg-charged-grey-700 w-full mb-2 mt-4"
          ></span>

          <v-layout pt-4>
            <v-spacer></v-spacer>
            <!-- Mobile -->
            <div class="flex justify-end mt-3 md:hidden">
              <v-btn
                class="capitalize font-work-sans font-medium text-base rounded-lg"
                color="primary"
                block
                :disabled="isValid()"
                :loading="loadingData"
                @click="handleSubmitStepSix"
              >
                Submit Order
              </v-btn>
            </div>
            <!-- Dekstop -->
            <div class="md:flex justify-end mt-3 hidden">
              <v-btn
                class="capitalize font-work-sans font-medium text-base rounded-lg"
                color="primary"
                right
                :disabled="isValid()"
                :loading="loadingData"
                @click="handleSubmitStepSix"
              >
                Submit Order
              </v-btn>
            </div>
          </v-layout>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { postOrderApi } from "../../../../services/back-end-ruby/Vehicle";
import { formatPrice } from "../../../../store/form-subscribe/data/non-gojek/utils";

export default {
  name: "step-6",
  props: {
    windowWidth: Number,
    isMobileDevice: Boolean,
    goToForm: Function,
    handleSubmitStepSix: Function,
  },
  computed: {
    getLanguage() {
      return this.$store.state.user.language;
    },
    getUser() {
      return this.$store.state.user;
    },
    getDataKostumisasi() {
      return this.$store.getters.getDataKostumisasi;
    },
    getDataInformasiPribadi() {
      return this.$store.getters.getDataInformasiPribadi;
    },
    getDataDomisili() {
      return this.$store.getters.getDataDomisili;
    },
    getTotalAmount() {
      return this.$store.getters.getTotalAmount;
    },
    getSelectedVehiclePrice() {
      return this.$store.getters.getSelectedVehiclePrice;
    },
  },
  data() {
    return {
      formatPrice,
      loading: false,
      loadingData: false,
      loadingBtnStep: false,
      subscriptionPolicy: false,
      loadingScreen: false,
      lang: this.$store.state.lang,
      selectedBike: null,
      alertStatus: "",
      alertText: "",
      alertDialog: false,
      selectedPricing: null,
      selectedBikeInfos: null,
      personalInfos: null,
      domisiliInfos: null,
      userInfos: null,
      subscriptionPolicyTrim: {
        en: "The second party declares that all data and information and financing requirements documents are complete,",
        id: "Pihak kedua menyatakan bahwa semua data dan informasi serta dokumen persyaratan telah lengkap,",
      },
      subscriptionPolicyFull: {
        en: "The second party declares that all data and information and financing requirements documents are complete, correct in accordance with the original; and hereby consents to and permits the First Party to contact any source to obtain or examine the necessary information. I acknowledge and agree that the data, information and documents provided belong to the first party that do not need to be returned and therefore can be used for all purposes related to business activities, including but not limited to being able to be provided to third parties, including in the process of surveys, collection of consumer obligations, product/service offerings; if there is a change, then the latest data that will be stated in the financing agreement; I will further comply with and be bound by the applicable terms and conditions and I understand that the First Party reserves the right to accept or reject this submission.",
        id: "Pihak kedua menyatakan bahwa semua data dan informasi serta dokumen persyaratan telah lengkap, benar sesuai dengan aslinya; dan dengan ini menyetujui dan mengizinkan Pihak Pertama untuk menghubungi sumber manapun untuk mendapatkan atau memeriksa informasi yang diperlukan. Saya mengetahui dan menyetujui bahwa data, informasi dan dokumen yang diberikan adalah milik pihak pertama yang tidak perlu dikembalikan dan karenanya dapat digunakan untuk segala keperluan yang berkaitan dengan kegiatan usaha, termasuk namun tidak terbatas untuk dapat diberikan kepada pihak ketiga, termasuk dalam proses survei, pengumpulan kewajiban konsumen, penawaran produk/jasa; apabila terjadi perubahan, maka data yang akan dicantumkan dalam perjanjian pembiayaan adalah data yang terbaru; Saya selanjutnya akan mematuhi dan terikat dengan syarat dan ketentuan yang berlaku dan saya memahami bahwa Pihak Pertama berhak untuk menerima atau menolak pengajuan ini.",
        readMoreActive: true,
      },
      newsletter: "",
      totalAmount: 0,
    };
  },
  watch: {
    windowWidth() {
      if (this.windowWidth < 768) {
        this.showBottomNav = false;
      } else {
        this.showBottomNav = false;
      }
    },
  },
  async mounted() {},
  async created() {
    this.$store.commit("setDataStepDone", false);
  },
  methods: {
    getData() {
      this.selectedPricing = this.getSelectedVehiclePrice;
      this.selectedBikeInfos = this.getDataKostumisasi;
      this.personalInfos = this.getDataInformasiPribadi;
      this.domisiliInfos = this.getDataDomisili;
      this.userInfos = this.getUser;
      this.totalAmount = this.getTotalAmount;

      console.log("INFOS", this.getDataKostumisasi);
    },
    openPolicy() {
      window.open("/pdf/sales_agreement.pdf", "_blank");
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    routerGo(route) {
      window.open(route, "_blank");
    },
    async submit() {
      this.loadingScreen = true;
      this.loadingData = true;

      const invoiceData = {
        invoice: {
          amount: 100000,
          description: "Order Deposit Payment",
          purpose: "Booking",
          customer: {
            givenNames: this.personalInfos?.name,
            nik: this.personalInfos?.nik,
            surname: this.personalInfos?.name,
            email: this.personalInfos?.emailPribadi,
            emailPribadi: this.personalInfos?.emailPribadi,
            phoneNumber: this.personalInfos?.phoneNumberPribadi,
            phoneNumberPribadi: this.personalInfos?.phoneNumberPribadi,
            address: {
              subDistrict: this.domisiliInfos?.serviceSubDistrict,
              district: this.domisiliInfos?.serviceDistrict,
              city: this.domisiliInfos?.serviceCity,
              province: this.domisiliInfos?.serviceProvince,
              country: "Indonesia",
              postalCode: this.domisiliInfos?.serviceZip,
              state: this.domisiliInfos?.serviceVillage,
              streetLine1: this.domisiliInfos?.address,
              streetLine2: this.domisiliInfos?.address,
            },
            homeOwnershipStatus: this.domisiliInfos?.homeOwnershipStatus,
            lengthOfStay: this.domisiliInfos?.lengthOfStay,
            customerType: this.userInfos?.customerType,
            referralCode: "",
            motherName: this.personalInfos?.motherName,
            companyName: this.personalInfos?.companyName,
            jobPosition: this.personalInfos?.jobPosition,
            employmentStatus: this.personalInfos?.employmentStatus ?? "",
            yearOfExperience: this.personalInfos?.yearOfExperience,
            salary: this.personalInfos?.salary,
            creditCardLimit: this.personalInfos?.creditCardLimit ?? "",
            maritalStatus: this.personalInfos?.maritalStatus,
            spouseCompanyName: this.personalInfos?.spouseCompanyName,
            spousePosition: this.personalInfos?.spousePositionLabel,
            spouseEmploymentStatus: this.personalInfos?.spouseEmploymentStatus,
            spouseYearOfExperience: this.personalInfos?.spouseYearOfExperience,
            spouseSalary: this.personalInfos?.spouseSalary,
            newsletter: this.newsletter || "",
            selectedRear: this.selectedBikeInfos?.accessories?.title?.en || "",
          },
        },
        bikeType: this.selectedBikeInfos?.bike.name,
        chargerType: this.selectedBikeInfos?.typeCharge.title,
        batteryQuantity: this.selectedBikeInfos?.qtyBattery.qty,
        subscriptionPlan: this.selectedPricing.plan,
        subscriptionBonus: this.selectedPricing.bonus.en,
        depositAmount: 0,
        subscriptionAmount: this.totalAmount,
        subscriptionTax: 0,
        subscriptionInsurance: 0,
        bikeColor: this.selectedBikeInfos?.color.title.en,
        bikeColorHex: this.selectedBikeInfos?.color.color,
        shippingMethod: "",
        bikeAccessories: this.selectedBikeInfos?.accessories ?? null,
      };

      const token = this.$cookies.get("token_api");
      const subsId = this.$store.getters.getIdSubs;
      await postOrderApi(token, {
        subscription_checkbox: this.subscriptionPolicy,
        subscription_id: subsId,
      }).then((res) => {
        if (res.meta.code == 200) {
          let invoiceUrl = "booking/" + subsId;
          this.redirectToInvoice(invoiceUrl);
          this.loadingScreen = false;
          this.$store.commit("setInvoice", invoiceData);
        } else {
          this.loadingScreen = false;
          this.alertDialog = true;
          this.loadingData = false;
          this.alertStatus = "failed";
          this.alertText = { en: "Transaction Failed", id: "Transaksi Gagal" };
        }
      });
    },
    redirectToInvoice(url) {
      this.$router.push(url);
    },
    isValid() {
      return !this.subscriptionPolicy || this.loadingData;
    },
    closeAlertDialog() {
      this.alertDialog = false;
    },
  },
};
</script>

<style></style>